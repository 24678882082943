import React, { Fragment, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Scrollbar from "../../components/scrollbar";
import Footer from "../../components/footer";
import Logo from "../../images/Bustan-Al-Sukkar--Logo-Final-web-022.png";
import ProfileSideBar from "./ProfileSideBar";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { InputAdornment } from "@material-ui/core";
import { Visibility, VisibilityOffRounded } from "@material-ui/icons";
import { toast } from "react-toastify";
import ReservaionHistory from "./ReservaionHistory";
import { useSelector, useDispatch } from "react-redux";
import { Global } from "../../Global";
import {
  GenerateGuest,
  setCredential,
} from "../../store/slices/credential-slice";

// import test1 from '../../images/testimonial/img-1.jpg'
// import test2 from '../../images/testimonial/img-2.jpg'
// import test3 from '../../images/testimonial/img-3.jpg'

const ProfilePage = () => {
  const isLogged = useSelector((state) => state.credential.isLogged);
  const customerId = useSelector((state) => state.credential.customerId);
  const customerToken = useSelector((state) => state.credential.customerToken);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [profileImage, setProfileImage] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfimNewPassword] = useState("");
  const [isNew, setIsNew] = useState(false);
  const [image, setImage] = useState("");

  const [reservaionsHistory, setReservaionsHistory] = useState([]);

  const ShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const UpdateDetail = async (e) => {
    e.preventDefault();
    if (firstName.trim() === "") {
      toast.error("First Name is required");
    } else if (lastName.trim() === "") {
      toast.error("Last Name is required");
    } else if (
      phoneNumber === "" ||
      phoneNumber === 0 ||
      phoneNumber.length < 8
    ) {
      toast.error("Valid phone Number is required");
    } else if (dateOfBirth === "") {
      toast.error("DOB  is required");
    } else if (
      email.trim() === "" ||
      !email.includes(".") ||
      !email.includes("@")
    ) {
      toast.error("Valid Email is required");
    } else {
      var myHeaders = new Headers();
      myHeaders.append("bs-api-key", Global.bs_api_key);
      let formdata = new FormData();
      formdata.append("FirstName", firstName.trim());
      formdata.append("LastName", lastName.trim());
      formdata.append("Email", email.toLocaleLowerCase().trim());
      formdata.append("PhoneNumber", phoneNumber);
      formdata.append("Dob", dateOfBirth);
      formdata.append("ProfilePicture", isNew ? profileImage : null);
      formdata.append("ProfilePictureUrl", isNew ? "" : profileImage);
      formdata.append("Sender", "C");
      formdata.append("SenderId", customerId);

      var requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: formdata,
      };

      let response = await fetch(
        Global.API_URL + "Client/EditCustomerProfile?customerid=" + customerId,
        requestOptions
      );
      let result = await response.json();
      if (response.ok) {
        if (result.code === 0) {
          toast.success(result.message);
          dispatch(
            setCredential({
              isLogged: true,
              guestId: "",
              token: "",
              customerId: customerId,
              customerToken: customerToken,
              firstName: firstName,
              lastName: lastName,
              email: email,
            })
          );
        } else if (result.code === 1) {
          toast.success(result.message);
          let promise;
          result = dispatch(GenerateGuest());
          promise = Promise.resolve(result);
          promise.then((value) => {
            dispatch(
              setCredential({
                isLogged: false,
                guestId: value.message.guestId,
                token: value.message.token,
                customerId: 0,
                customerToken: "",
                firstName: "",
                lastName: "",
                email: "",
              })
            );
          });
          navigate("/");
        }
      } else {
        console.log(result);
      }
    }
  };
  const ResetPassword = async (e) => {
    e.preventDefault();
    if (oldPassword.trim() === "") {
      toast.error("Old Password is required");
    } else if (newPassword.trim() === "") {
      toast.error("New Password is required");
    } else if (confirmNewPassword.trim() === "") {
      toast.error("Confirm Password is required");
    } else {
      var strongRegex = new RegExp(
        "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&.?*])(?=.{8,})"
      );
      var valid = strongRegex.test(newPassword);
      if (valid && confirmNewPassword.trim() !== newPassword.trim()) {
        toast.error("New Password and Confirm Password must be matched");
      } else if (!valid) {
        toast.error("New Password must be strong");
      } else {
        try {
          const response = await fetch(
            Global.API_URL + "Client/ChangePassword?customerid=" + customerId,
            {
              method: "Put",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "bs-api-key": Global.bs_api_key,
              },
              body: JSON.stringify({
                oldPassword: oldPassword,
                newPassword: newPassword,
              }),
            }
          );
          if (!response.ok) {
            const result = await response.json();
            if (result.code === -4) {
              toast.error("Wrong Password");
            } else if (result.code === -5) {
              toast.error("Can't enter same password");
            } else {
              toast.error(result.message);
            }
          }

          const result = await response.json();
          toast.success(result.message);
          setOldPassword("");
          setNewPassword("");
          setConfimNewPassword("");
          setShowPassword(false);
        } catch (error) {}
      }
    }
  };
  const handleDrop = (e) => {
    if (e.target.files !== undefined) {
      const selectedFile = e.target.files[0];

      if (selectedFile !== undefined) {
        setProfileImage(selectedFile);

        // Read the file and convert it to a data URL
        const reader = new FileReader();
        reader.onload = () => {
          setImage(reader.result);
          setIsNew(true);
        };
        if (selectedFile) {
          reader.readAsDataURL(selectedFile);
        }
      }
    }
  };
  const fetchBookingHistory = async () => {
    try {
      const response = await fetch(
        Global.API_URL +
          "Client/GetCustomerBookingHistory?customerid=" +
          customerId,
        {
          method: "Get",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "bs-api-key": Global.bs_api_key,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Something went wrong!");
      }

      const result = await response.json();
      setReservaionsHistory(result.message);
    } catch (error) {}
  };
  useEffect(() => {
    const fetchDetail = async () => {
      try {
        const response = await fetch(
          Global.API_URL + "Client/GetCustomerProfile?customerid=" + customerId,
          {
            method: "Get",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "bs-api-key": Global.bs_api_key,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Something went wrong!");
        }

        const result = await response.json();
        setFirstName(result.message.firstName);
        setLastName(result.message.lastName);
        setPhoneNumber(result.message.phoneNumber);
        setProfileImage(result.message.profilePicture);
        setEmail(result.message.email);
        setDateOfBirth(result.message.dob);
      } catch (error) {}
    };
    if (isLogged) {
      fetchDetail();
    } else {
      navigate("/404");
    }
  }, [customerId, customerToken, isLogged, navigate]);

  return (
    <Fragment>
      <Navbar hclass={"wpo-header-style-3"} Logo={Logo} />
      <section className="service-single-section profile-section-padding">
        <div className="container">
          <div className="row">
            <ProfileSideBar
              page={page}
              setPage={setPage}
              fetchBookingHistory={fetchBookingHistory}
            />
            <div className="col col-lg-8 col-12">
              <div className="service-single-content">
                {parseInt(page) === 1 ? (
                  <Fragment>
                    <div className="d-flex justify-content-between">
                      <div>
                        <h2>Personal details</h2>
                        <p>Update your information</p>
                      </div>
                      <div>
                        <div className="container-profile" onClick={handleDrop}>
                          <div
                            className="outer"
                            style={{
                              background:
                                "url(" +
                                (isNew
                                  ? image
                                  : profileImage === undefined ||
                                    profileImage === ""
                                  ? "/images/default-avatar-profile.png"
                                  : Global.PHOTO_URL + profileImage) +
                                ") no-repeat center top/cover",
                            }}
                          >
                            <input
                              className="inputfile"
                              type="file"
                              name="pic"
                              onChange={handleDrop}
                              accept="image/*"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <Grid className="cuponWrap checkoutCard">
                      <Grid className="chCardBody">
                        <form
                          className="cuponForm"
                          onSubmit={(e) => UpdateDetail(e)}
                        >
                          <Grid container spacing={3}>
                            <Grid item sm={6} xs={12}>
                              <label>First Name</label>
                              <TextField
                                fullWidth
                                name="fname"
                                type="text"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                className="formInput radiusNone"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                              />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                              <label>Last Name</label>
                              <TextField
                                fullWidth
                                name="lname"
                                type="text"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                className="formInput radiusNone"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                              />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                              <label>Email</label>
                              <TextField
                                fullWidth
                                name="email"
                                type="email"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                className="formInput radiusNone"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                              />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                              <label>Phone Number</label>
                              <TextField
                                fullWidth
                                name="phone"
                                type="number"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                className="formInput radiusNone"
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <label>Date of Birth</label>
                              <TextField
                                fullWidth
                                name="dob"
                                type="datetime-local"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                className="formInput radiusNone"
                                value={dateOfBirth}
                                onChange={(e) => setDateOfBirth(e.target.value)}
                              />
                            </Grid>
                            <Grid item sm={3} xs={12}>
                              <Grid className="formFooter mt-20">
                                <Button
                                  fullWidth
                                  className="cBtn cBtnLarge cBtnTheme mt-20 ml-15"
                                  type="submit"
                                >
                                  Submit
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </form>
                      </Grid>
                    </Grid>
                  </Fragment>
                ) : parseInt(page) === 2 ? (
                  <Fragment>
                    <div className="d-flex justify-content-between">
                      <div>
                        <h2>Reset Your Password</h2>
                        <p></p>
                      </div>
                    </div>
                    <Grid className="cuponWrap checkoutCard">
                      <Grid className="chCardBody">
                        <form
                          className="cuponForm"
                          onSubmit={(e) => ResetPassword(e)}
                        >
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <label>Old Password</label>
                              <TextField
                                fullWidth
                                name="oldPassword"
                                type={showPassword ? "text" : "password"}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment
                                      position="end"
                                      className="password-icon"
                                      onClick={() => ShowPassword()}
                                    >
                                      {showPassword ? (
                                        <Visibility />
                                      ) : (
                                        <VisibilityOffRounded />
                                      )}
                                    </InputAdornment>
                                  ),
                                }}
                                className="formInput radiusNone"
                                value={oldPassword}
                                onChange={(e) => setOldPassword(e.target.value)}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <label>New Password</label>
                              <TextField
                                fullWidth
                                name="newPassword"
                                type={showPassword ? "text" : "password"}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                className="formInput radiusNone"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <label> Confirm New Password </label>
                              <TextField
                                fullWidth
                                name="confirmNewPassword"
                                type={showPassword ? "text" : "password"}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                className="formInput radiusNone"
                                value={confirmNewPassword}
                                onChange={(e) =>
                                  setConfimNewPassword(e.target.value)
                                }
                              />
                            </Grid>
                            <Grid item sm={3} xs={12}>
                              <Grid className="formFooter mt-20">
                                <Button
                                  fullWidth
                                  className="cBtn cBtnLarge cBtnTheme mt-20 ml-15"
                                  type="submit"
                                >
                                  Submit
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </form>
                      </Grid>
                    </Grid>
                  </Fragment>
                ) : (
                  <ReservaionHistory reservaionsHistory={reservaionsHistory} />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default ProfilePage;
