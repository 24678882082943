import React from "react";
import { Global } from "../../Global";

const About2 = ({ content, widgetTitle, widgetDescription }) => {
  return (
    <section className="wpo-about-section section-padding">
      <div className="container">
        <div className="wpo-about-section-wrapper">

            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-img">
                  <img src={Global.PHOTO_URL + content.contentImage} alt="" />
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-content">
                  <div className="about-title">
                    <span>{widgetTitle}</span>
                    <h2>{widgetDescription}</h2>
                  </div>
                  <div className="wpo-about-content-inner">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: content.contentDescription,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
         
        </div>
      </div>
    </section>
  );
};

export default About2;
