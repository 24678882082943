import React, { Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import SectionTitleS2 from "../SectionTitleS2";
import { useEffect } from "react";
import { useState } from "react";
import { Global } from "../../Global";

const Rooms = ({ rooms, widgetTitle, widgetDescription }) => {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  useEffect(() => {
    if (rooms) {
      setProducts(rooms);
    }
    if (widgetDescription) {
      setDescription(widgetDescription);
    }
    if (widgetTitle) {
      setTitle(widgetTitle);
    }
  }, [rooms, widgetDescription, widgetTitle]);

  return (
    <div className="wpo-room-area section-padding">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-xl-6 col-md-8">
            <SectionTitleS2
              backlight="back-light"
              MainTitle={title}
              description={description}
            />
          </div>
        </div>
        <div className="room-wrap">
          <div className="row">
            {products.length > 0 &&
              products.map((product, pitem) => (
                <div
                  className="col-lg-3 col-md-6 col-12"
                  key={pitem}
                  onClick={() => navigate(`/room-single/${product.roomId}`)}
                >
                  <div className="room-item">
                    <div className="room-img">
                      <img src={Global.PHOTO_URL + product.roomImage} alt="" />
                    </div>
                    <div className="room-content">
                      <h2>
                        <Link
                          onClick={ClickHandler}
                          to={`/room-single/${product.roomId}`}
                        >
                          {product.roomName}
                        </Link>
                      </h2>
                      <ul>
                        <li>
                          <i className="fi flaticon-expand-arrows"></i>
                          {product.numberOfBedrooms} Bedroom
                        </li>
                        <li>
                          <i className="fi flaticon-bed"></i>
                          {product.numberOfBeds} Bed
                        </li>
                        {/* <li>
                          <i className="fi flaticon-bathtub"></i>
                          {product.numberOfBaths} Bathroom
                        </li> */}
                      </ul>
                      <h3>
                        {product.pricePerNightWeekday ===
                        product.pricePerNightWeekend ? (
                          "$" + product.pricePerNightWeekday
                        ) : (
                          <Fragment>
                            ${product.pricePerNightWeekday} - $
                            {product.pricePerNightWeekend}
                          </Fragment>
                        )}
                      </h3>
                      <div className="add-to-cart">
                        <button
                          className="theme-btn mt-3"
                          data-bs-toggle="tooltip"
                          data-bs-html="true"
                          title="Add to Cart"
                          onClick={() =>
                            navigate(`/room-single/${product.roomId}`)
                          }
                        >
                          Select this room
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rooms;
