import React, { Fragment, useState } from "react";
import Grid from "@material-ui/core/Grid";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
// import Checkbox from "@material-ui/core/Checkbox";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Logo from "../../images/Bustan-Al-Sukkar--Logo-Final-web-022.png";
import "./style.scss";
import Navbar from "../../components/Navbar";
import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";
import { Global } from "../../Global";
import { setCredential } from "../../store/slices/credential-slice";
import SocialLoginButton from "../../components/SocialLogin/SocialLoginButton";


const LoginPage = (props) => {

  const push = useNavigate();
  const dispatch = useDispatch();

  let strongPasswordRegex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&.?*])(?=.{8,})"
  );

  const [value, setValue] = useState({
    email: "",
    password: "",
    remember: false,
  });

  const changeHandler = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
    validator.showMessages();
  };

  // const rememberHandler = () => {
  //   setValue({ ...value, remember: !value.remember });
  // };

  const [validator] = React.useState(
    new SimpleReactValidator({
      className: "errorMessage",
    })
  );


  const submitForm = async (e) => {
    e.preventDefault();
    if (value.email.trim() === "" || value.password.trim() === "") {
      toast.error("Empty field is not allowed!");
    } else if (!value.email.includes(".") && !value.email.includes("@")) {
      toast.error("Valid email is required");
    } else if (!strongPasswordRegex.test(value.password)) {
      toast.error(
        "Password must be minimum 8 characters consisting of numbers, uppercase and lowercase letters and symbols"
      );
    } else {
      const response = await fetch(Global.API_URL + "Customer/CustomerLogin", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "bs-api-key": Global.bs_api_key,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: value.email.trim().toLocaleLowerCase(),
          password: value.password,
        }),
      });
      const result = await response.json();
      if (response.ok) {
        dispatch(
          setCredential({
            isLogged: true,
            guestId: "",
            token: "",
            customerId: result.message.customerId,
            customerToken: result.message.token,
            firstName: result.message.firstName,
            lastName: result.message.lastName,
            email: result.message.email,
          })
        );
        push("/my-account");
      } else {
        toast.error(result.message);
      }
    }
  };
  return (
    <Fragment>
      <Navbar hclass={"wpo-header-style-3"} Logo={Logo} />
      <section
        className="error-404-section section-padding-login"
        style={{
          background:
            "url('/images/Login-1.jpg') no-repeat center top/cover",
        }}
      >
        <div className="container">
          <Grid className="loginWrapper">
            <Grid className="loginForm">
              <h2>Sign In</h2>
              <p>Sign in to your account</p>
              <form onSubmit={submitForm}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      className="inputOutline"
                      fullWidth
                      placeholder="E-mail"
                      value={value.email}
                      variant="outlined"
                      name="email"
                      label="E-mail *"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onBlur={(e) => changeHandler(e)}
                      onChange={(e) => changeHandler(e)}
                    />
                    {validator.message("email", value.email, "required|email")}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      className="inputOutline"
                      fullWidth
                      placeholder="Password"
                      value={value.password}
                      variant="outlined"
                      name="password"
                      type="password"
                      label="Password *"
                      autoComplete={value.remember ? "current-password" : ""}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onBlur={(e) => changeHandler(e)}
                      onChange={(e) => changeHandler(e)}
                    />
                    {validator.message("password", value.password, "required")}
                  </Grid>
                  <Grid item xs={12}>
                    <Grid className="formAction">
                      {/* <FormControlLabel
                        control={
                          <Checkbox
                            checked={value.remember}
                            onChange={rememberHandler}
                          />
                        }
                        label="Remember Me"
                      /> */}
                      <Link to="/forgot-password">Forgot Password?</Link>
                    </Grid>
                    <Grid className="formFooter">
                      <Button fullWidth className="cBtnTheme" type="submit" onClick={submitForm}>
                        Login
                      </Button>
                    </Grid>
                    <Grid className="loginWithSocial">
                      <SocialLoginButton />
                    </Grid>
                    <p className="noteHelp">
                      Don't have an account?{" "}
                      <Link to="/register">Create free account</Link>
                    </p>
                  </Grid>
                </Grid>
              </form>
              <div className="shape-img">
                <i className="fi flaticon-honeycomb"></i>
              </div>
            </Grid>
          </Grid>
        </div>
      </section>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default LoginPage;
