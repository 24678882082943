import React, { useEffect, useRef } from "react";
// import About from "../../components/about";
import About2 from "../../components/about2/about2";
import Banner from "../../components/Banner";
import BlogSection from "../../components/BlogSection";
// import Destination from "../../components/Destination";
import Footer from "../../components/footer";
import Hero from "../../components/hero";
import Navbar from "../../components/Navbar";
import Newslatter from "../../components/Newslatter/Newslatter";
import PricingSection from "../../components/PricingSection";
import Rooms from "../../components/Rooms/Rooms";
import Scrollbar from "../../components/scrollbar";
import SearchSection from "../../components/SearchSection";
import ServiceSection from "../../components/ServiceSection";
import AllowedItemSection from "../../components/AllowedItemSection";
import ServiceSection2 from "../../components/ServiceSection2/ServiceSection2";
import AllowedItemSection2 from "../../components/AllowedItemSection2/AllowedItemSection2";
import Testimonial from "../../components/Testimonial";
import Logo from "../../images/Bustan-Al-Sukkar--Logo-Final-web-012.png";
import { useState } from "react";
import { Global } from "../../Global";
import { Fragment } from "react";
import Skeleton from "../Skeleton";

const HomePage = () => {
  const [widgets, setWidgets] = useState([]);
  const [isFetching, setIsFetching] = useState(1);
  const [count, setCount] = useState(0);
  const homePageRef = useRef();
  const [isLoading, setIsLoading] = useState(true);

  const GetWidgetByPageID = async (Skip, Array) => {
    setIsFetching(1);
    let newArray = [];
    try {
      const response = await fetch(
        Global.API_URL +
          "Client/GetDynamicWidgetsByPageId?pageid=1&Skip=" +
          Skip +
          "&Take=10",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "bs-api-key": Global.bs_api_key,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Something went wrong!");
      }
      if (response.ok) {
        const result = await response.json();

        if (result.message.length !== 0) {
          setIsFetching(0);
        }
        if (Skip === 0) {
          newArray = result.message;
        } else {
          newArray = Array.concat(result.message);
        }
        setWidgets(newArray);
        setCount(count + 10);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    GetWidgetByPageID(0, []);
  }, []);

  window.onscroll = function () {
    if (homePageRef.current && isFetching === 0) {
      GetWidgetByPageID(count, widgets);
    }
  };
  return (
    <div ref={homePageRef}>
      {widgets ? (
        <Fragment>
          <Navbar topbarBlock={"wpo-header-style-2"} Logo={Logo} />
          <Hero />
          <SearchSection />
          {!isLoading ? (
            widgets.map((widget, index) => {
              if (widget.widgetTypeId === 1) {
                if (widget.widgetStyleId === 8) {
                  return (
                    <AllowedItemSection
                      key={index}
                      allowedItems={widget.allowedItems}
                      page={window.location.pathname}
                    />
                  );
                } else {
                  return (
                    <AllowedItemSection2
                      key={index}
                      allowedItems={widget.allowedItems}
                      widgetTitle={widget.widgetTitle}
                      widgetDescription={widget.widgetDescription}
                      widgetUrl={widget.widgetUrl}
                      page={window.location.pathname}
                    />
                  );
                }
              } else if (widget.widgetTypeId === 2) {
                return (
                  <Banner
                    key={index}
                    banner={widget.banner}
                    widgetTitle={widget.widgetTitle}
                    widgetDescription={widget.widgetDescription}
                    widgetUrl=""
                  />
                );
              } else if (widget.widgetTypeId === 3) {
                return (
                  <About2
                    key={index}
                    content={widget.content}
                    widgetTitle=""
                    widgetDescription={widget.widgetDescription}
                  />
                );
              } else if (widget.widgetTypeId === 4) {
                return (
                  <BlogSection
                    key={index}
                    news={widget.news}
                    widgetTitle={widget.widgetTitle}
                    page={window.location.pathname}
                  />
                );
              } else if (widget.widgetTypeId === 6) {
                return (
                  <Testimonial key={index} reviews={widget.hotelRatings} />
                );
              } else if (widget.widgetTypeId === 7) {
                if (widget.widgetStyleId === 3) {
                  return (
                    <Rooms
                      key={index}
                      rooms={widget.rooms}
                      widgetTitle={widget.widgetTitle}
                      widgetDescription={widget.widgetDescription}
                    />
                  );
                } else {
                  return (
                    <PricingSection
                      key={index}
                      rooms={widget.rooms}
                      widgetTitle={widget.widgetTitle}
                      widgetDescription={widget.widgetDescription}
                    />
                  );
                }
              } else {
                if (widget.widgetStyleId === 6) {
                  return (
                    <ServiceSection
                      key={index}
                      services={widget.roomServices}
                      page={window.location.pathname}
                    />
                  );
                } else {
                  return (
                    <ServiceSection2
                      key={index}
                      services={widget.roomServices}
                      widgetTitle={widget.widgetTitle}
                      widgetDescription={widget.widgetDescription}
                      widgetUrl={widget.widgetUrl}
                      page={window.location.pathname}
                    />
                  );
                }
              }
            })
          ) : (
            <Skeleton />
          )}
          <Newslatter />
          <Footer />
          <Scrollbar />
        </Fragment>
      ) : (
        <div className="egns-preloader">
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-6">
                <div className="circle-border">
                  <div className="moving-circle"></div>
                  <div className="moving-circle"></div>
                  <div className="moving-circle"></div>
                  <svg
                    width="180px"
                    height="150px"
                    viewBox="0 0 187.3 93.7"
                    preserveAspectRatio="xMidYMid meet"
                  >
                    <path
                      stroke="#df865d"
                      id="outline"
                      fill="none"
                      strokeWidth="4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeMiterlimit="10"
                      d="M93.9,46.4c9.3,9.5,13.8,17.9,23.5,17.9s17.5-7.8,17.5-17.5s-7.8-17.6-17.5-17.5c-9.7,0.1-13.3,7.2-22.1,17.1 c-8.9,8.8-15.7,17.9-25.4,17.9s-17.5-7.8-17.5-17.5s7.8-17.5,17.5-17.5S86.2,38.6,93.9,46.4z"
                    ></path>
                    <path
                      id="outline-bg"
                      opacity="0.05"
                      fill="none"
                      stroke="#df865d"
                      strokeWidth="4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeMiterlimit="10"
                      d="M93.9,46.4c9.3,9.5,13.8,17.9,23.5,17.9s17.5-7.8,17.5-17.5s-7.8-17.6-17.5-17.5c-9.7,0.1-13.3,7.2-22.1,17.1 c-8.9,8.8-15.7,17.9-25.4,17.9s-17.5-7.8-17.5-17.5s7.8-17.5,17.5-17.5S86.2,38.6,93.9,46.4z"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HomePage;
