import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
// import FacebookLogin from "react-facebook-login";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import { Global } from "../../Global";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setCredential } from "../../store/slices/credential-slice";
import {FcPhoneAndroid} from "react-icons/fc";

const SocialLoginButton = () => {
  const push = useNavigate();
  const dispatch = useDispatch();
  const googleConfig = {
    clientId:
      "1015305808854-4co875es984m6v72f4vpq5fgf3f13uqu.apps.googleusercontent.com",
  };
  // const responseFacebook = async (response) => {
  //   console.log(response)
  //   var name = response.name.split(" ");
  //   // response.picture.data.url

  //   // try {
  //   //   const resp = await fetch(Global.API_URL + "Customer/CustomerRegister", {
  //   //     method: "POST",
  //   //     headers: {
  //   //       Accept: "application/json",
  //   //       "bs-api-key": Global.bs_api_key,
  //   //       "Content-Type": "application/json",
  //   //       "Access-Control-Allow-Origin": Global.HostAPI,
  //   //     },
  //   //     body: JSON.stringify({
  //   //       firstName: name[0],
  //   //       lastName: name[1],
  //   //       email: response.email,
  //   //       password: "",
  //   //       loginTypeId: 3,
  //   //     }),
  //   //   });
  //   //   const result = await resp.json();
  //   //   console.log(result);
  //   //   if (resp.ok) {
  //   //     dispatch(
  //   //       setCredential({
  //   //         isLogged: true,
  //   //         guestId: "",
  //   //         token: "",
  //   //         customerId: result.customerId,
  //   //         customerToken: result.message.jwtToken,
  //   //         firstName: name[0],
  //   //         lastName: name[1],
  //   //         email: response.email,
  //   //       })
  //   //     );
  //   //     push("/my-account");
  //   //   } else {
  //   //     toast.error(result.message);
  //   //   }
  //   // } catch (ex) {
  //   //   console.log(ex.Message);
  //   // }
  // };
  const responseGoogle = async (response) => {
    const credential = response.credential;
    const decodedToken = jwt_decode(credential);
    try {
      const response = await fetch(
        Global.API_URL + "Customer/CustomerRegister",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "bs-api-key": Global.bs_api_key,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": Global.HostAPI,
          },
          body: JSON.stringify({
            firstName: decodedToken.given_name,
            lastName: decodedToken.family_name,
            email: decodedToken.email,
            password: "",
            loginTypeId: 2,
            profilePicture: "",
            GoogleToken: credential
          }),
        }
      );
      const result = await response.json();
      if (response.ok) {
        dispatch(
          setCredential({
            isLogged: true,
            guestId: "",
            token: "",
            customerId: result.customerId,
            customerToken: result.message.jwtToken,
            firstName: decodedToken.given_name,
            lastName: decodedToken.family_name,
            email: decodedToken.email,
          })
        );
        push("/my-account");
      } else {
        toast.error(result.message);
      }
    } catch (ex) {
      console.log(ex.Message);
    }
  };
  return (
    <Fragment>
      {/* <FacebookLogin
        // appId="1416657839095947"
        appId="292958759828894"
        fields="name,email,picture"
        callback={responseFacebook}
        className="facebook"
        icon="fa fa-facebook"
        textButton=""
      /> */}

      <GoogleOAuthProvider {...googleConfig}>
        <GoogleLogin
          className="googlebutton"
          text=""
          shape="circle "
          theme="outline"
          type="icon"
          size="large"
          onSuccess={responseGoogle}
          cookiePolicy={"single_host_origin"}
        />
      </GoogleOAuthProvider>

      {/* <GoogleOAuthProvider {...googleConfig}>
        <GoogleLogin
          buttonText="Sign in with Google"
          onSuccess={responseGoogle}
          cookiePolicy={"single_host_origin"}
          render={(renderProps) => (
            <button
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
            >
              <FcPhoneAndroid /> Sign in with Google
            </button>
          )}
        />
      </GoogleOAuthProvider> */}
    </Fragment>
  );
};

export default SocialLoginButton;
