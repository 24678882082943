import React, { Fragment, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Global } from "../../Global";
import { useEffect } from "react";

const SearchRooms = ({
  products,
  isLoading,
  CheckIn,
  CheckOut,
  adults,
  children,
  beds,
}) => {
  const navigate = useNavigate();
  const [urlParams,setUrlParams]=useState("")
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  useEffect(()=>{
    if(CheckIn){
      setUrlParams("?CheckIn=" +
      CheckIn.trim() +
      "&CheckOut=" +
      CheckOut.trim() +
      "&adults=" +
      adults.trim() +
      "&children=" +
      children.trim()+
      "&beds=" +
      beds.trim())
   
    }
  },[CheckIn])

  return (
    <div className="wpo-room-area section-padding">
      <div className="container">
        <div className="room-wrap">
          <div className="row">
            {isLoading ? (
              [1, 2, 3].map((item) => (
                <div
                  className="col-lg-4 col-md-6 col-12 margintop-50"
                  key={item}
                >
                  <div className="skeleton-loading">
                    <div className="skeleton-image"></div>
                    <div className="skeleton-text"></div>
                    <div className="skeleton-text second-text"></div>
                    <div className="skeleton-text"></div>
                    <div className="skeleton-button"></div>
                  </div>
                </div>
              ))
            ) : products.length > 0 ? (
              products.map((product, pitem) =>
                CheckIn ? (
                  <div
                    className="col-lg-4 col-md-6 col-12"
                    key={pitem}
                    onClick={() =>
                      navigate(`/room-single/${product.roomId}${urlParams.trim()}`)
                    }
                  >
                    <div className="room-item">
                      <div className="room-img">
                        <img
                          src={
                            product.roomImage
                              ? Global.PHOTO_URL + product.roomImage
                              : "/product/1.jpg"
                          }
                          alt=""
                        />
                      </div>
                      <div className="room-content">
                        <h2>
                          <Link
                            onClick={ClickHandler}
                            to={`/room-single/${product.roomId}${urlParams.trim()}`}
                          >
                            {product.roomName}
                          </Link>
                        </h2>
                        <ul>
                          <li>
                            <strong>Capacity:</strong> {product.capacity}
                          </li>
                          <li>
                            <strong>Max Children:</strong> {product.maxChildren}
                          </li>
                        </ul>
                        <h3>
                          {product.pricePerNightWeekDay ===
                          product.pricePerNightWeekEnd ? (
                            "$" + product.pricePerNightWeekDay
                          ) : (
                            <Fragment>
                              ${product.pricePerNightWeekDay} - $
                              {product.pricePerNightWeekEnd}
                            </Fragment>
                          )}
                        </h3>
                        <div className="add-to-cart">
                          <button
                            className="theme-btn mt-3"
                            data-bs-toggle="tooltip"
                            data-bs-html="true"
                            title="Add to Cart"
                            onClick={() =>
                              navigate(`/room-single/${product.roomId}${urlParams.trim()}`)
                            }
                          >
                            Select this room
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className="col-lg-4 col-md-6 col-12"
                    key={pitem}
                    onClick={() => navigate(`/room-single/${product.roomId}`)}
                  >
                    <div className="room-item">
                      <div className="room-img">
                        <img
                          src={
                            product.roomImage
                              ? Global.PHOTO_URL + product.roomImage
                              : "/product/1.jpg"
                          }
                          alt=""
                        />
                      </div>
                      <div className="room-content">
                        <h2>
                          <Link
                            onClick={ClickHandler}
                            to={`/room-single/${product.roomId}`}
                          >
                            {product.roomName}
                          </Link>
                        </h2>
                        <ul>
                          <li>
                            <strong>Capacity:</strong> {product.capacity}
                          </li>
                          <li>
                            <strong>Max Children:</strong> {product.maxChildren}
                          </li>
                        </ul>
                        <h3>
                          {product.pricePerNightWeekDay ===
                          product.pricePerNightWeekEnd ? (
                            "$" + product.pricePerNightWeekDay
                          ) : (
                            <Fragment>
                              ${product.pricePerNightWeekDay} - $
                              {product.pricePerNightWeekEnd}
                            </Fragment>
                          )}
                        </h3>
                        <div className="add-to-cart">
                          <button
                            className="theme-btn mt-3"
                            data-bs-toggle="tooltip"
                            data-bs-html="true"
                            title="Add to Cart"
                            onClick={() =>
                              navigate(`/room-single/${product.roomId}`)
                            }
                          >
                            Select this room
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )
            ) : (
              <div className="wpo-reservation-area">
                <div className="container">
                  <div className="wpo-reservation-wrap">
                    <div className="reservation-slider">
                      <div className="container-reservation">
                        <img
                          src="/images/villa-100.png"
                          alt="reservation"
                          className="icon"
                        />
                        <p className="text-icon"> No Villas found</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-xl-6 col-md-8">
            <SectionTitleS2
              backlight="back-light"
              MainTitle={"Search Result"}
            />
          </div>
        </div>
        <div className="room-wrap">
          <div className="row">
            {products && products.length > 0 ? (
              products.map((product, pitem) => (
                <div className="col-lg-4 col-md-6 col-12" key={pitem}>
                  <div className="room-item">
                    <div className="room-img">
                      <img
                        src={
                          product.roomImage
                            ? Global.PHOTO_URL + product.roomImage
                            : "/product/1.jpg"
                        }
                        alt=""
                      />
                    </div>
                    <div className="room-content">
                      <h2>
                        <Link
                          onClick={ClickHandler}
                          to={`/room-single/${product.roomId}`}
                        >
                          {product.roomName}
                        </Link>
                      </h2>
                      <ul>
                        <li>
                          <strong>Capacity:</strong> {product.capacity}
                        </li>
                        <li>
                          <strong>Max Children:</strong> {product.maxChildren}
                        </li>
                      </ul>
                      <h3>
                        ${product.pricePerNightWeekDay} - $
                        {product.pricePerNightWeekEnd}
                      </h3>
                      <div className="add-to-cart">
                        <button
                          className="theme-btn mt-3"
                          data-bs-toggle="tooltip"
                          data-bs-html="true"
                          title="Add to Cart"
                          onClick={() =>
                            navigate(`/room-single/${product.roomId}`)
                          }
                        >
                          Select this room
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="wpo-reservation-area">
                <div className="container">
                  <div className="wpo-reservation-wrap">
                    <div className="reservation-slider">
                      <div className="container-reservation">
                        <img
                          src="/images/villa-100.png"
                          alt="reservation"
                          className="icon"
                        />
                        <p className="text-icon"> No Villas found</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default SearchRooms;
