import React from "react";
import BlogSidebar from "../../components/BlogSidebar";
import { Global } from "../../Global";

const BlogSingle = ({ news, isLoading }) => {

  return (
    <section className="wpo-blog-single-section section-padding">
      <div className="container">
        {isLoading ? (
          [1, 2, 3].map((item) => (
            <div className="col-lg-4 col-md-6 col-12" key={item}>
              <div className="skeleton-loading">
                <div className="skeleton-image"></div>
                <div className="skeleton-text"></div>
                <div className="skeleton-text second-text"></div>
                <div className="skeleton-text"></div>
                <div className="skeleton-button"></div>
              </div>
            </div>
          ))
        ) : (
          <div className="row">
            <div className={`col col-lg-8 col-12`}>
              <div className="wpo-blog-content">
                <div className="post format-standard-image">
                  <div className="entry-media">
                    <img src={Global.PHOTO_URL + news.newsImage} alt="" />
                  </div>
                  <div className="entry-meta"></div>
                  <h2>{news.newsTitle}</h2>
                  <p>
                    {news.newsDescription}
                  </p>
                </div>
              </div>
            </div>
            <BlogSidebar />
          </div>
        )}
      </div>
    </section>
  );
};

export default BlogSingle;
