import React from 'react';
import ContactForm from '../ContactFrom'


const ContactpageSec = () => {

    return (
      <section className="wpo-contact-pg-section section-padding">
        <div className="container">
          <div className="row">
            <div className="col col-lg-10 offset-lg-1">
              <div className="office-info">
                <div className="row">
                  <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                    <div className="office-info-item">
                      <div className="office-info-icon">
                        <div className="icon">
                          <i className="fi flaticon-maps-and-flags"></i>
                        </div>
                      </div>
                      <div className="office-info-text">
                        <h2>Address</h2>
                        <p>New Ainab Village, Aley, Lebanon</p>
                      </div>
                    </div>
                  </div>
                  <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                    <div className="office-info-item">
                      <div className="office-info-icon">
                        <div className="icon">
                          <i className="fi flaticon-email"></i>
                        </div>
                      </div>
                      <div className="office-info-text">
                        <h2>Email Us</h2>
                        <p>
                          <a href="mailto:info@bustanalsukkar.com">
                            info@bustanalsukkar.com
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                    <div className="office-info-item">
                      <div className="office-info-icon">
                        <div className="icon">
                          <i className="fi flaticon-phone-call"></i>
                        </div>
                      </div>
                      <div className="office-info-text">
                        <h2>Call Now</h2>
                        <p>
                          <a href="tel:0096170658888">+961 70 658 888</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="wpo-contact-title">
                <h2>Have Any Question?</h2>
                <p>
                  It is a long established fact that a reader will be distracted
                  content of a page when looking.
                </p>
              </div>
              <div className="wpo-contact-form-area">
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
        <section className="wpo-contact-map-section">
          <div className="wpo-contact-title">
            <h2>Our Location</h2>
          </div>

          <div className="wpo-contact-map">
            <iframe
              title="bustanalsukkar"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3316.680650163608!2d35.5393975!3d33.7689173!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151f190bd59fe033%3A0x44af2a60fae4e994!2sBustan%20Al%20Sukkar!5e0!3m2!1sen!2slb!4v1693386001424!5m2!1sen!2slb"
            ></iframe>
          </div>
        </section>
      </section>
    );
        
}

export default ContactpageSec;
