import React, { Fragment, useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";
import Logo from "../../images/Bustan-Al-Sukkar--Logo-Final-web-022.png";
import Navbar from "../../components/Navbar";
import Button from "@material-ui/core/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { Global } from "../../Global";

const ConfirmEmail = () => {
  const [message, setMessage] = useState("");
  const [isPassed, setIsPassed] = useState(0);

  const location = useLocation();
  const navigate = useNavigate();
  const data = new URLSearchParams(location.search);

  useEffect(() => {
    const Confirm = async () => {
      const response = await fetch(
        Global.API_URL +
          "Customer/VerifyCustomer?Email=" +
          data.get("email").replaceAll("%20", "+") +
          "&Token=" +
          data.get("id").replaceAll("%20", "+"),
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "bs-api-key": Global.bs_api_key,
          },
        }
      );
      const result = await response.json();
      if (response.ok) {
        setMessage("Successfully confirmed, you can login now");
        setIsPassed(true);
      } else {
        setMessage(result.message);
        setIsPassed(false);
      }
    };

    Confirm();
  }, []);

  return (
    <Fragment>
      <Navbar hclass="wpo-header-style-3" Logo={Logo} />
      <section
        className="error-404-section section-padding-login"
        style={{
          background:
            "url('/images/Login-1.jpg') no-repeat center top/cover",
        }}
      >
        <div className="container">
          <Grid className="loginWrapper">
            <Grid className="loginForm">
              <h2>Email Confirmation</h2>
              <p>{message}</p>
              <div className="shape-img">
                <i className="fi flaticon-honeycomb"></i>
              </div>
              <Button
                fullWidth
                className="cBtnTheme"
                onClick={() => {
                  isPassed ? navigate("/login") : navigate("/");
                }}
              >
                {isPassed ? "Login" : "Go Back To Home"}
              </Button>
            </Grid>
          </Grid>
        </div>
      </section>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default ConfirmEmail;
