import React, { useState, Fragment } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Global } from "../../Global";

const RoomReviews = ({ room, setRoom }) => {
  const { customerToken, token, isLogged, customerId, guestId } = useSelector(
    (state) => state.credential
  );
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [starRating, setStarRating] = useState(0);
  const [ratingComment, setRatingComment] = useState("");
  const [disabled, setDisabled] = useState(false);

  const SubmitHandler = async (e) => {
    e.preventDefault();
    if (starRating === 0) {
      toast.error("Enter your Rate");
    } else if (ratingComment.trim() === "") {
      toast.error("Add your Review");
    } else if (firstName.trim() === "") {
      toast.error("First Name is required");
    } else if (lastName.trim() === "") {
      toast.error("LastName is required");
    } else if (email.trim() === "") {
      toast.error("Email is required");
    } else if (phoneNumber.trim() === "") {
      toast.error("Phone Number is required");
    } else {
      setDisabled(true);
      try {
        let Token;
        if (isLogged) {
          Token = customerToken;

          const response = await fetch(
            Global.API_URL + "HotelRating/AddHotelRating",
            {
              method: "Post",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "bs-api-key": Global.bs_api_key,
                AuthorizationCustomer: Token,
              },
              body: JSON.stringify({
                hotelGuestEmail: email,
                isRegistered: true,
                customerId: customerId,
                guestId: 0,
                firstName: firstName,
                lastName: lastName,
                starRating: starRating,
                ratingComment: ratingComment,
                phoneNumber: phoneNumber,
              }),
            }
          );

          if (!response.ok) {
            setDisabled(false);
            throw new Error("Something went wrong!");
          }
          if (response.ok) {
            const result = await response.json();
            if (result.code === 0) {
              let roomRating = { ...room };
              if (roomRating.ratings.length > 3) {
                let temp = roomRating.ratings[0];
                roomRating.ratings[0] = {
                  firstName: firstName,
                  lastName: lastName,
                  ratingComment: ratingComment,
                  starRating: parseInt(starRating),
                };
                roomRating.ratings.push(temp);
              } else {
                roomRating.ratings.push({
                  firstName: firstName,
                  lastName: lastName,
                  ratingComment: ratingComment,
                  starRating: parseInt(starRating),
                });
              }
              setRoom(roomRating);
              setEmail("");
              setFirstName("");
              setLastName("");
              setPhoneNumber("");
              setRatingComment("");
              setStarRating("0");
              toast.success(result.message);
              setDisabled(false);
            } else {
              setDisabled(false);
              toast.error(result.message);
            }
          }
        } else {
          Token = token;

          const response = await fetch(
            Global.API_URL + "HotelRating/AddHotelRating",
            {
              method: "Post",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "bs-api-key": Global.bs_api_key,
                AuthorizationGuest: Token,
              },
              body: JSON.stringify({
                hotelGuestEmail: email,
                isRegistered: true,
                customerId: 0,
                guestId: guestId,
                firstName: firstName,
                lastName: lastName,
                starRating: starRating,
                ratingComment: ratingComment,
                phoneNumber: phoneNumber,
              }),
            }
          );

          if (!response.ok) {
            setDisabled(false);
            throw new Error("Something went wrong!");
          }
          if (response.ok) {
            const result = await response.json();
            if (result.code === 0) {
              let roomRating = { ...room };
              if (room.rating.length > 3) {
                let temp = room.rating[0];
                room.rating[0] = {
                  firstName: firstName,
                  lastName: lastName,
                  ratingComment: ratingComment,
                  starRating: parseInt(starRating),
                };
                roomRating.ratings.push(temp);
              } else {
                roomRating.ratings.push({
                  firstName: firstName,
                  lastName: lastName,
                  ratingComment: ratingComment,
                  starRating: parseInt(starRating),
                });
              }
              setRoom(roomRating);
              setEmail("");
              setFirstName("");
              setLastName("");
              setPhoneNumber("");
              setRatingComment("");
              setStarRating("0");
              toast.success(result.message);
              setDisabled(false);
            } else {
              setDisabled(false);
              toast.error(result.message);
            }
          }
        }
      } catch (ex) {
        setDisabled(false);
        toast.error(ex.Message);
      }
    }
  };
  return (
    <Fragment>
      {room.ratings.length > 0 && (
        <div className="room-review">
          <div className="room-title">
            <h2>Room Reviews</h2>
          </div>
          {room.ratings.map((rating, index) => (
            <div className="review-item" key={index}>
              <div className="review-img">{/* <img src={rv1} alt="" /> */}</div>
              <div className="review-text">
                <div className="r-title">
                  <h2>{rating.firstName + " " + rating.lastName}</h2>
                  <ul>
                    {[...Array(rating.starRating)].map((star, index) => (
                      <li key={index}>
                        <i className="fa fa-star light" aria-hidden="true"></i>
                      </li>
                    ))}
                    {[...Array((rating.starRating - 5) * -1)].map(
                      (star, index) => (
                        <li key={index}>
                          <i className="fa fa-star" aria-hidden="true"></i>
                        </li>
                      )
                    )}
                  </ul>
                </div>
                <p>{rating.ratingComment} </p>
              </div>
            </div>
          ))}
        </div>
      )}
      <div className="add-review">
        <div className="room-title">
          <h2>Add Review</h2>
        </div>
        <div className="wpo-blog-single-section review-form ">
          <div className="give-rat-sec">
            <p>Your rating *</p>
            <div className="give-rating">
              <label>
                <input
                  type="radio"
                  name="stars"
                  value="1"
                  onChange={(e) => setStarRating(e.target.value)}
                />
                <span className="icon">★</span>
              </label>
              <label>
                <input
                  type="radio"
                  name="stars"
                  value="2"
                  onChange={(e) => setStarRating(e.target.value)}
                />
                <span className="icon">★</span>
                <span className="icon">★</span>
              </label>
              <label>
                <input
                  type="radio"
                  name="stars"
                  value="3"
                  onChange={(e) => setStarRating(e.target.value)}
                />
                <span className="icon">★</span>
                <span className="icon">★</span>
                <span className="icon">★</span>
              </label>
              <label>
                <input
                  type="radio"
                  name="stars"
                  value="4"
                  onChange={(e) => setStarRating(e.target.value)}
                />
                <span className="icon">★</span>
                <span className="icon">★</span>
                <span className="icon">★</span>
                <span className="icon">★</span>
              </label>
              <label>
                <input
                  type="radio"
                  name="stars"
                  value="5"
                  onChange={(e) => setStarRating(e.target.value)}
                />
                <span className="icon">★</span>
                <span className="icon">★</span>
                <span className="icon">★</span>
                <span className="icon">★</span>
                <span className="icon">★</span>
              </label>
            </div>
          </div>
          <div className="review-add">
            <div className="comment-respond">
              <form
                id="commentform"
                className="comment-form"
                onSubmit={SubmitHandler}
              >
                <div className="form-inputs">
                  <input
                    placeholder="Your First Name*"
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                  <input
                    placeholder="Your Last Name*"
                    type="text"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
                <div className="form-inputs">
                  <input
                    placeholder="Your Email*"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <input
                    placeholder="Your Phone Number*"
                    type="text"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </div>
                <div className="form-textarea">
                  <textarea
                    id="comment"
                    placeholder="Your Review*"
                    value={ratingComment}
                    onChange={(e) => setRatingComment(e.target.value)}
                  ></textarea>
                </div>
                <div className="form-submit">
                  <input
                    id="submit"
                    value="Submit Now"
                    type="submit"
                    disabled={!room.isBooked || disabled}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default RoomReviews;
