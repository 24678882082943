import React from "react";
import { Link } from "react-router-dom";
import CheckAvailabilitySideBar from "./CheckAvailabilitySideBar";
import RoomReviews from "./RoomReviews";
import { Global } from "../../Global";

const RoomSidebar = ({
  startDate,
  setEndDate,
  endDate,
  setStartDate,
  room,
  setRoom,
  contactUs,
  beds,
  adults,
  children,
}) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };
  
  return (
    <div className="col-lg-4 col-12">
      <div className="blog-sidebar room-sidebar ">
        <div className="desktop-side-bar">
          <CheckAvailabilitySideBar
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            room={room}
            setRoom={setRoom}
            beds={beds}
            adults={adults}
            children={children}
          />
        </div>

        <div className="wpo-single-sidebar">
          <div className="wpo-service-widget widget">
            <ul>
              <li>
                <button>
                  BEDROOMS :{" "}
                  <span className="room-detail-sidebar">
                    {room.totalnbofBedrooms} Bedrooms
                  </span>{" "}
                </button>
              </li>
              <li>
                <button>
                  BEDS :{" "}
                  <span className="room-detail-sidebar">
                    {room.totalnbofBeds} Beds
                  </span>{" "}
                </button>
              </li>
              <li>
                <button>
                  Bathroom :{" "}
                  <span className="room-detail-sidebar">
                    {room.nbofBaths} BATHS
                  </span>{" "}
                </button>
              </li>
              <li>
                <button>
                  Floor :{" "}
                  <span className="room-detail-sidebar">
                    {room.floor} floor
                  </span>{" "}
                </button>
              </li>
              <li>
                <button>
                  Breakfast :{" "}
                  <span className="room-detail-sidebar">
                    {" "}
                    {room.breakfastIncluded
                      ? "Included"
                      : "$" + room.breakfastPrice}
                  </span>{" "}
                </button>
              </li>
              <li>
                <button>
                  OCCUPANCY :{" "}
                  <span className="room-detail-sidebar">
                    {room.maxOccupanciesAdults} adults{" "}
                  </span>{" "}
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div className="mobile-side-bar mb-55">
          <RoomReviews room={room} setRoom={setRoom} />
        </div>
        {room.discoverImages.length > 0 && (
          <div className="widget wpo-instagram-widget">
            <div className="widget-title">
              <h3>Discover Our Rooms</h3>
            </div>
            <ul className="d-flex">
              {room.discoverImages.slice(0, 6).map((image, pitem) => (
                <li key={pitem}>
                  <Link
                    onClick={ClickHandler}
                    to={`/room-single/${room.roomId}`}
                  >
                    <img src={Global.PHOTO_URL + image} alt="" />
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )}
        <div className="wpo-contact-widget widget">
          <h2>{contactUs.title}</h2>
          <p>{contactUs.body}</p>
          <a href="/contact">Contact Us</a>
        </div>
      </div>
    </div>
  );
};

export default RoomSidebar;
