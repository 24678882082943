import React from "react";
import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import { Global } from "../../Global";

const AllowedItemSection = ({ allowedItems, svClass, page }) => {

  var settings = {
    dots: false,
    arrows: true,
    speed: 1200,
    slidesToShow: 4,
    infinite: allowedItems && allowedItems.length > 4 ? true : false,
    autoplay: true,
    autoplaySpeed: 3500,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className={`wpo-service-area-s2 space-section ${svClass}`}>
      <div className="wpo-service-wrap">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-xl-12 col-12">
              <div className="wpo-service-items wpo-service-active">
                <Slider {...settings}>
                  {allowedItems.map((item, index) => (
                    <div className="wpo-service-item" key={index}>
                      <div className="wpo-service-img">
                        <img src={Global.PHOTO_URL + item.itemImage} alt="" />
                      </div>
                      <div className="wpo-service-text">
                        <img
                          src={Global.PHOTO_URL + item.itemIcon}
                          alt=""
                        ></img>
                        <label>
                          {item.ItemName}
                        </label>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllowedItemSection;
