import React, { useEffect } from "react";
import AllRoute from "../router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../sass/style.scss";
import { useDispatch, useSelector } from "react-redux";
import { GetMenu, setMenu } from "../../store/slices/menu-slice";
import { getSlides, setSlides } from "../../store/slices/widget-slice";
import {
  GenerateGuest,
  setCredential,
} from "../../store/slices/credential-slice";
// import Snowfall from "../Snowfall/Snowfall";

const App = () => {
  const dispatch = useDispatch();
  const isLogged = useSelector((state) => state.credential.isLogged);
  const guest = useSelector((state) => state.credential.guestId);
  
  useEffect(() => {
    let result;
    let promise;
    result = dispatch(GetMenu());
    promise = Promise.resolve(result);
    promise.then((value) => {
      dispatch(setMenu({ menu: value }));
    });
    result = dispatch(getSlides());
    promise = Promise.resolve(result);
    promise.then((value) => {
      dispatch(setSlides({ slides: value }));
    });
    if (!isLogged && (guest === "" || guest === undefined)) {
      result = dispatch(GenerateGuest());
      promise = Promise.resolve(result);
      promise.then((value) => {
        dispatch(
          setCredential({
            isLogged: false,
            guestId: value.message.guestId,
            token: value.message.token,
            customerId: 0,
            customerToken: "",
            firstName: "",
            lastName: "",
            email: "",
          })
        );
      });
    }
  }, [dispatch, guest, isLogged]);

  return (
    <div className="App" id="scrool">
      {/* <Snowfall/> */}
      <AllRoute />
      <ToastContainer />
    </div>
  );
};

export default App;
