import React from 'react'

const HeaderTopbar = () => {
    return (
        <div className="topbar">
            <div className="container-fluid">
                <div className="row">
                    <div className="col col-lg-7 col-md-8 col-sm-12 col-12 d-none d-lg-block d-md-block d-xl-block">
                        <div className="contact-intro">
                            <ul>
                                <li><i className="fi flaticon-email"></i><a href='mailto:info@bustanalsukkar.com'>info@bustanalsukkar.com</a> </li>
                                <li><i className="fi flaticon-phone-call"></i> <a href='tel:0096170658888'>+961 70 658 888</a></li>
                                <li><i className="fi ti-location-pin"></i> New Ainab Village, Aley, Lebanon</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col col-lg-5 col-md-4 col-sm-12 col-12">
                        <div className="contact-info">
                            <ul>
                                {/* <li className="language">
                                En
                                    <select name="language" id="language">
                                        <option value="">English</option>
                                        <option value="">Arabic</option>
                                        <option value="">France</option>
                                    </select>
                                </li> */}
                                <li className="language"><a href="https://www.facebook.com/profile.php?id=100092194246556"><i className="fi flaticon-facebook-app-symbol"></i></a></li>
                                <li><a href="https://www.linkedin.com/company/bustan-al-sukkar/"><i className="fi flaticon-linkedin"></i></a></li>
                                <li><a href="https://instagram.com/bustanalsukkar?igshid=MzRlODBiNWFlZA=="><i className="fi flaticon-instagram"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderTopbar;