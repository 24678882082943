import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import SectionTitleS2 from "../SectionTitleS2";
import { Global } from "../../Global";

const ClickHandler = () => {
  window.scrollTo(10, 0);
};

const PricingSection = ({ rooms, widgetTitle, widgetDescription }) => {
  return (
    <section className="wpo-pricing-section section-padding">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6">
            <SectionTitleS2 backlight="back-light" MainTitle={widgetTitle} />
          </div>
        </div>
        <div className="wpo-pricing-wrap">
          <div className="row">
            {rooms.slice(0, 3).map((room, index) => (
              <div className="col col-lg-4 col-md-6 col-12" key={index}>
                <div className="wpo-pricing-item">
                  <div className="wpo-pricing-top">
                    <div className="wpo-pricing-img">
                      <img src={Global.PHOTO_URL + room.roomImage} alt="" />
                    </div>
                    <div className="wpo-pricing-text">
                      <h4>{room.roomName}</h4>
                      {room.pricePerNightWeekday ===
                      room.pricePerNightWeekend ? (
                        <h2>
                          ${room.pricePerNightWeekday}
                          <span>/Per Night</span>
                        </h2>
                      ) : (
                        <Fragment>
                          <h2>
                            ${room.pricePerNightWeekday}
                            <span>/Per Weekday Night</span>
                          </h2>
                          <h2>
                            ${room.pricePerNightWeekend}
                            <span>/Per Weekend Night</span>
                          </h2>
                        </Fragment>
                      )}
                    </div>
                  </div>
                  <div className="wpo-pricing-bottom">
                    <div className="wpo-pricing-bottom-text">
                      <ul>
                        {room.breakfastIncluded && <li>Breakfast Included</li>}
                        <li>{room.numberOfGuests} Guest</li>
                        <li>{room.numberOfBedrooms} Bedroom</li>
                        <li>{room.numberOfBeds} Bed</li>
                        <li>{room.numberOfBaths} Bathroom</li>
                      </ul>
                      <Link
                        onClick={ClickHandler}
                        className="theme-btn"
                        to={`/room-single/${room.roomId}`}
                      >
                        Discover Room
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default PricingSection;
