import { Global } from "../../Global";

const { createSlice } = require("@reduxjs/toolkit");


const credentialSlice = createSlice({
  name: "credential",
  initialState: {
    isLogged: false,
    guestId: "",
    customerId: 0,
    customerToken: "",
    firstName: "",
    lastName: "",
    email: "",
  },
  reducers: {
    setCredential(state, action) {
      const isLogged = action.payload.isLogged;
      const id = action.payload.guestId;
      const token= action.payload.token;
      const customer = action.payload.customerId;
      const customerToken = action.payload.customerToken;
      const firstName = action.payload.firstName;
      const lastName = action.payload.lastName;
      const email = action.payload.email;

      if (isLogged) {
        return (state = {
          isLogged: true,
          guestId: id,
          token:token,
          customerId: customer,
          customerToken: customerToken,
          firstName: firstName,
          lastName: lastName,
          email: email,
        });
      }
      if (!isLogged) {
        return (state = {
          isLogged: false,
          guestId: id,
          token:token,
          customerId: customer,
          customerToken: customerToken,
          firstName: "",
          lastName: "",
          email: "",
        });
      }
    },
  },
});

export const GenerateGuest =  () => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        Global.API_URL + "Guest/CreateGuest",{
          method:"Post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "bs-api-key": Global.bs_api_key,
          }
        }
      );
      if (!response.ok) {
        throw new Error("Something went wrong!");
      }

      const result = await response.json();

      return result;
    } catch (error) {
    }
  };
};


export const { setCredential } = credentialSlice.actions;
export default credentialSlice.reducer;
