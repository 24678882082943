import React, { useState } from 'react';
import ListItem from "@material-ui/core/ListItem";
import { Link } from "react-router-dom";
import './style.css';
import { useSelector } from 'react-redux';

const MobileMenu = () => {
    const menu = useSelector((state) => state.menu.menu);
    const [menuActive, setMenuState] = useState(false);

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <div>
            <div className={`mobileMenu ${menuActive ? "show" : ""}`}>
                <div className="menu-close">
                    <div className="clox" onClick={() => setMenuState(!menuActive)}><i className="ti-close"></i></div>
                </div>

                <ul className="responsivemenu">
                    {menu && menu.map((item, mn) => {
                        return (
                            <ListItem  key={mn}>
                            <Link className='active' onClick={ClickHandler}
                                        to={item.menuURL}>{item.menuName}</Link>
                            </ListItem>
                        )
                    })}
                </ul>

            </div>

            <div className="showmenu" onClick={() => setMenuState(!menuActive)}>
                <button type="button" className="navbar-toggler open-btn">
                    <span className="icon-bar first-angle"></span>
                    <span className="icon-bar middle-angle"></span>
                    <span className="icon-bar last-angle"></span>
                </button>
            </div>
        </div>
    )
}

export default MobileMenu;