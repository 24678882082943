import React, { useState } from "react";
import MobileMenu from "../MobileMenu";
import { Link, useNavigate } from "react-router-dom";
import HeaderTopbar from "../HeaderTopbar";
import { useSelector } from "react-redux";

const Header = (props) => {
  const menu = useSelector((state) => state.menu.menu);
  const { isLogged, firstName } = useSelector((state) => state.credential);
  const navigate = useNavigate();

  const [menuActive, setMenuState] = useState(false);
  const [keyword, setKeyWord] = useState("");

  const SubmitHandler = (e) => {
    e.preventDefault();
    if (keyword.trim() !== "") {
      setMenuState(!menuActive)
      navigate("/search-result?keyword=" + keyword.replaceAll(" ", "_"));
      
    }
  };

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <header id="header" className={props.topbarBlock}>
      <HeaderTopbar />
      <div className={`wpo-site-header ${props.hclass}`}>
        <nav className="navigation navbar navbar-expand-lg navbar-light">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-lg-3 col-md-3 col-3 d-lg-none dl-block">
                <div className="mobail-menu">
                  <MobileMenu />
                </div>
              </div>
              <div className="col-lg-2 col-md-6 col-6">
                <div className="navbar-header">
                  <Link
                    onClick={ClickHandler}
                    className="navbar-brand logo"
                    to="/home"
                  >
                    <img src={props.Logo} alt="" />
                  </Link>
                </div>
              </div>
              <div
                className={
                  isLogged
                    ? "col-lg-8 col-md-1 col-1"
                    : "col-lg-9 col-md-1 col-1"
                }
              >
                <div
                  id="navbar"
                  className="collapse navbar-collapse navigation-holder"
                >
                  <button className="menu-close">
                    <i className="ti-close"></i>
                  </button>
                  <ul className="nav navbar-nav mb-2 mb-lg-0">
                    {menu &&
                      menu.map((m) => {
                        return (
                          <li key={m.menuId}>
                            <Link onClick={ClickHandler} to={m.menuURL}>
                              {m.menuName}
                            </Link>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
              <div
                className={
                  isLogged
                    ? "col-lg-2 col-md-2 col-2"
                    : "col-lg-1 col-md-2 col-2"
                }
              >
                <div className="header-right">
                  <div className="header-search-form-wrapper">
                    <div className="cart-search-contact">
                      <button
                        onClick={() => setMenuState(!menuActive)}
                        className="search-toggle-btn"
                      >
                        <i
                          className={`fi ${
                            menuActive ? "ti-close" : "fi flaticon-search"
                          }`}
                        ></i>
                      </button>
                      <div
                        className={`header-search-form ${
                          menuActive ? "header-search-content-toggle" : ""
                        }`}
                      >
                        <form onSubmit={SubmitHandler}>
                          <div>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search here..."
                              value={keyword}
                              onChange={(e) => setKeyWord(e.target.value)}
                            />
                            <button type="submit">
                              <i className="fi flaticon-search"></i>
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>

                  <div className="mini-cart">
                    {isLogged ? (
                      <button
                        className="profile-btn"
                        onClick={() => navigate("/my-account")}
                      >
                        Welcome, {firstName}
                      </button>
                    ) : (
                      <button
                        className="cart-toggle-btn"
                        onClick={() => navigate("/login")}
                      >
                        <i className="fa flaticon-user"></i>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
