import React from "react";
import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import { Global } from "../../Global";

const ReservaionHistory = ({ reservaionsHistory }) => {
  var settings = {
    dots: false,
    arrows: true,
    speed: 1200,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3500,
  };

  return (
    <div className="wpo-reservation-area">
      <div className="container">
        <div className="wpo-reservation-wrap">
          <div className="reservation-slider">
            <Slider {...settings}>
              {reservaionsHistory && reservaionsHistory.length > 0 ? (
                reservaionsHistory.map((tstml, tsm) => (
                  <div className="wpo-reservation-item" key={tsm}>
                    <div className="wpo-reservation-img">
                      <img src={Global.PHOTO_URL + tstml.roomImageUrl} alt="" />
                    </div>
                    <div className="wpo-reservation-content">
                      <h2>{tstml.roomName}</h2>
                      <p>Checkin: {tstml.checkInDate}</p>
                      <p className="reservation-p">Checkout: {tstml.checkOutDate}</p>
                      <p className="reservation-p breakfast">{tstml.roomIsBreakfastIncluded ? "BreakFast is Included" : "$ "+tstml.bookingBreakfastFee}</p>
                      <p className="reservation-p total-price">Total Price: <span> $ {tstml.bookingTotalPrice} </span> </p>
                    </div>
                  </div>
                ))
              ) : (
                <div className="container-reservation">
                  <img
                    src="/images/calendar-plus-100.png"
                    alt="reservation"
                    className="icon"
                  />
                  <p className="text-icon">
                    {" "}
                    This page shows all of your bookings.
                  </p>
                  <p>No reservations found.</p>
                </div>
              )}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReservaionHistory;
