import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import "./style.scss";

const OrderRecivedSec = () => {
  const { roomName, pricePerNight, nbofNights } = useSelector(
    (state) => state.cart.details
  );

  return (
    <section className="cart-recived-section section-padding">
      <div className="container">
        <div className="row">
          <div className="order-top">
            <h2>
              Thank You For Your Booking!{" "}
              <span>Your booking has been received</span>
            </h2>
            <Link to="/" className="theme-btn">
              Back Home
            </Link>
          </div>
          <Grid className="cartStatus">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid className="cartTotals">
                  <h4>Booking Details</h4>
                  <Table>
                    <TableBody>
                      <TableRow className="totalProduct">
                        <TableCell>Room Name</TableCell>
                        <TableCell align="right">{roomName}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Sub Price</TableCell>
                        <TableCell align="right">
                          ${Math.round(pricePerNight)} x{" "}
                          {nbofNights + " nights"}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <b>Total Price</b>
                        </TableCell>
                        <TableCell align="right">
                          <b> ${Math.round(pricePerNight) * nbofNights} </b>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </section>
  );
};

export default OrderRecivedSec;
