import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { setDetail } from "../../store/slices/cart-slice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Global } from "../../Global";
import { toast } from "react-toastify";

const CheckAvailabilitySideBar = ({
  startDate,
  setEndDate,
  endDate,
  setStartDate,
  room,
  setRoom,
  beds,
  adults,
  children,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpenStart, setIsOpenStart] = useState(false);
  const [isOpenEnd, setIsOpenEnd] = useState(false);
  const [nbofNights, setNbofNights] = useState(0);
  const { customerToken, token, customerId } = useSelector(
    (state) => state.credential
  );
  // const [excludeDates, setExludedDate] = useState({});

  const [includeDateIntervals, setIncludeDateIntervals] = useState([]);

  const [endDateSelected, setEndDateSelected] = useState(false);

  const [adult, setCount] = useState(1);
  const adultincrementCount = () => {
    if (adult === room.maxOccupanciesAdults) {
      setCount(parseInt(room.maxOccupanciesAdults));
    } else {
      setCount(parseInt(adult) + 1);
    }
  };
  const adultdecrementCount = () => {
    if (adult > 1) {
      setCount(parseInt(adult) - 1);
    }
  };

  const [child, setChild] = useState(0);
  const childincrementCount = () => {
    if (child === room.maxOccupanciesChildren) {
      setChild(parseInt(room.maxOccupanciesChildren));
    } else {
      setChild(parseInt(child) + 1);
    }
  };
  const childdecrementCount = () => {
    if (child > 0) {
      setChild(parseInt(child) - 1);
    }
  };

  const [bed, setBed] = useState(1);
  const bedincrementCount = () => {
    if (bed === room.totalnbofBeds) {
      setBed(parseInt(room.totalnbofBeds));
    } else {
      setBed(parseInt(bed) + 1);
    }
  };
  const beddecrementCount = () => {
    if (bed > 1) {
      setBed(parseInt(bed) - 1);
    }
  };
  function getDaysArray(startDate, endDate) {
    const availableIntervals = [];
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);

    // Initialize the current start date and end date
    let currentStart = new Date(startDateObj);
    let currentEnd = new Date(startDateObj);
    currentEnd.setDate(currentEnd.getDate() + 1);

    // Iterate through the dates to create intervals
    while (currentStart <= endDateObj) {
      // If the current end date is greater than the end date, adjust it
      if (currentEnd > endDateObj) {
        currentEnd = new Date(endDateObj);
      }

      // Format the interval as an object and add it to the array
      const interval = {
        start: currentStart.toISOString().split("T")[0],
        end: currentEnd.toISOString().split("T")[0],
      };

      availableIntervals.push(interval);

      // Move to the next day
      currentStart.setDate(currentStart.getDate() + 1);
      currentEnd.setDate(currentEnd.getDate() + 1);
    }

    return availableIntervals;
  }

  function getAllDatesNotInInterval(intervals) {
    const unbookedIntervals = [];
    const now = new Date();
    const fiveYearsFromNow = new Date(now);
    fiveYearsFromNow.setFullYear(now.getFullYear() + 5);

    // Create an object to store booked date ranges as keys
    const bookedDates = {};

    for (const interval of intervals) {
      const start = new Date(interval.start).getTime();
      const end = new Date(interval.end).getTime();

      // Store booked date ranges as keys in the object
      for (
        let timestamp = start;
        timestamp <= end;
        timestamp += 24 * 60 * 60 * 1000
      ) {
        bookedDates[new Date(timestamp).toDateString()] = true;
      }
    }

    let currentStart = now.getTime();

    // Iterate through the five-year date range and find unbooked intervals
    for (
      let timestamp = now.getTime();
      timestamp <= fiveYearsFromNow.getTime();
      timestamp += 24 * 60 * 60 * 1000
    ) {
      const currentDate = new Date(timestamp);
      const currentDateStr = currentDate.toDateString();

      if (bookedDates[currentDateStr]) {
        // If a booked date is encountered, create an unbooked interval
        if (currentStart < timestamp) {
          unbookedIntervals.push({
            start: new Date(currentStart),
            end: new Date(timestamp - 24 * 60 * 60 * 1000),
          });
        }
        currentStart = timestamp + 24 * 60 * 60 * 1000;
      }
    }
    // Check if there's an unbooked interval until the end date
    if (currentStart <= fiveYearsFromNow.getTime()) {
      unbookedIntervals.push({
        start: new Date(currentStart),
        end: new Date(fiveYearsFromNow.getTime() - 24 * 60 * 60 * 1000),
      });
    }

    return unbookedIntervals;
  }

  function findNextInterval(bookedDates, startDate) {
    // Parse the startDate string into a Date object
    const startDateObj = new Date(startDate);

    // Iterate through the booked dates
    for (const bookedDate of bookedDates) {
      const startDateRange = new Date(bookedDate.start);

      // Check if the start date of the booked date is greater than the provided start date
      if (startDateRange > startDateObj) {
        // Format the start and end dates of the first greater interval as strings
        const greaterStartDateStr = startDateRange.toISOString().split("T")[0];
        const greaterEndDateStr = new Date(bookedDate.end)
          .toISOString()
          .split("T")[0];

        return { start: greaterStartDateStr, end: greaterEndDateStr };
      }
    }

    // If no greater interval is found, return null or an appropriate value
    return null;
  }

  function getIntervalsAfterStartDate(startDate) {
    // Parse the startDate string into a Date object
    const startDateObj = new Date(startDate);

    // Initialize an array to store the result
    const intervalsAfterStartDate = [];

    // Calculate the end date as 5 years from the start date
    const endDate = new Date(startDateObj);
    endDate.setFullYear(endDate.getFullYear() + 5);

    // Define the interval duration in days (e.g., 7 days for one week)
    const intervalDuration = 80;

    // Iterate from the start date to the end date and add intervals to the result array
    while (startDateObj < endDate) {
      // Create an interval object with start and end dates
      const intervalStartDate = new Date(startDateObj);
      const intervalEndDate = new Date(startDateObj);
      intervalEndDate.setDate(intervalEndDate.getDate() + intervalDuration);

      // Format the interval as an object and add it to the array
      const interval = {
        start: intervalStartDate,
        end: intervalEndDate,
      };

      intervalsAfterStartDate.push(interval);

      // Increment the date by the interval duration
      startDateObj.setDate(startDateObj.getDate() + intervalDuration);
    }

    return intervalsAfterStartDate;
  }

  function formatIntervals(intervals) {
    const formattedIntervals = [];

    for (const interval of intervals) {
      const startDate = new Date(interval.start);
      const endDate = new Date(interval.end);

      const formattedInterval = {
        start: startDate,
        end: endDate,
      };

      formattedIntervals.push(formattedInterval);
    }

    return formattedIntervals;
  }

  const ChangeStartDate = (date) => {
    const availableDateRange = findNextInterval(room.bookedDates, date);
    let availableDates;
    setIncludeDateIntervals([]);
    setStartDate("");
    setEndDate("");
    setEndDateSelected(false);
    if (availableDateRange) {
      availableDates = getDaysArray(
        date.toISOString().split("T")[0],
        availableDateRange.start
      );
      const formattedIntervals = formatIntervals(availableDates);
      setIncludeDateIntervals(formattedIntervals);
    } else {
      availableDates = getIntervalsAfterStartDate(date);
      setIncludeDateIntervals(availableDates);
    }
    setStartDate(date);
    setIsOpenStart(false);
    setIsOpenEnd(true);
  };

  const ChangeEndDate = async (date) => {
    let end = new Date(date);
    if (date - startDate === 0) {
      end = date.setDate(date.getDate() + 1);
      setEndDate(end);
    } else {
      setEndDate(end);
    }



// Start Date
const originalstartDate = new Date(startDate);
const timeZoneOffsetMinutes = originalstartDate.getTimezoneOffset();
const adjustedDate = new Date(originalstartDate.getTime() - timeZoneOffsetMinutes * 60000);
const isoStartDateString = adjustedDate.toISOString();


//end
const originalendDate = new Date(end);
const EndtimeZoneOffsetMinutes = originalendDate.getTimezoneOffset();
const EndadjustedDate = new Date(originalendDate.getTime() - EndtimeZoneOffsetMinutes * 60000);
const isoendDateString = EndadjustedDate.toISOString();


    setEndDateSelected(true);
    let header =
      customerId === 0
        ? {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "bs-api-key": Global.bs_api_key,
              AuthorizationGuest: token,
            },
          }
        : {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "bs-api-key": Global.bs_api_key,
              AuthorizationCustomer: customerToken,
            },
          };

    try {
      const response = await fetch(
        Global.API_URL +
          "Client/GetClientRoomPriceById?RoomId=" +
          room.roomId +
          "&StartDate=" +
          isoStartDateString +
          "&EndDate=" +
          isoendDateString,
        header
      );
      if (response.ok) {
        let result = await response.json();
        if (result.code === 0) {
          setNbofNights(result.message.nbOfNights);
          setRoom((prevState) => ({
            ...prevState,
            pricePerNight: result.message.pricePerNight,
          }));
          const unbookedDates = getAllDatesNotInInterval(room.bookedDates);
          const adjustedDateIntervals = unbookedDates.map((interval) => ({
            start: new Date(interval.start.getTime() - 24 * 60 * 60 * 1000), // Subtract one day (in milliseconds)
            end: interval.end,
          }));
          setIncludeDateIntervals(adjustedDateIntervals);
          setIsOpenEnd(false);
        } else {
          toast.error(result.message);
        }
      }
    } catch (ex) {
      console.log(ex);
    }
  };

  const SubmitHandler = (e) => {
    e.preventDefault();
  };

  const Reserve = () => {
    if (!startDate || startDate === "") {
      setIsOpenStart(true);
      setIsOpenEnd(false);
    } else if (!endDate || endDate === "") {
      setIsOpenEnd(true);
      setIsOpenStart(false);
    } else {
      dispatch(
        setDetail({
          roomId: room.roomId,
          roomName: room.roomName,
          isBreakfastIncluded: room.isBreakfastIncluded,
          breakfastPrice: room.breakfastPrice,
          checkinDate: startDate.toISOString(),
          checkoutDate: endDate.toISOString(),
          pricePerNight: room.pricePerNight,
          nbofNights: nbofNights,
        })
      );

      navigate("/checkout");
    }
  };

  useEffect(() => {
    const unbookedDates = getAllDatesNotInInterval(room.bookedDates);
    const adjustedDateIntervals = unbookedDates.map((interval) => ({
      start: new Date(interval.start.getTime() - 24 * 60 * 60 * 1000), // Subtract one day (in milliseconds)
      end: interval.end,
    }));
    setIncludeDateIntervals(adjustedDateIntervals);
  }, [room]);


  useEffect(() => {
    if (beds) {
      setBed(beds);
      setChild(children);
      setCount(adults);
      setEndDateSelected(true)
    }
    if (startDate && endDate) {
      var date1 = new Date(startDate);
      var date2 = new Date(endDate);
      var timeDiff = Math.abs(date2.getTime() - date1.getTime());
      var numberOfNights = Math.ceil(timeDiff / (1000 * 3600 * 24));
      setNbofNights(numberOfNights);
    } else {
      setNbofNights(1);
    }
  }, [startDate, endDate, room]);

  return (
    <div className="widget check-widget">
      <h3>Choose Your Date</h3>
      <form onSubmit={SubmitHandler}>
        <div className="input-group date">
          <DatePicker
            selected={startDate}
            onChange={(date) => ChangeStartDate(date)}
            minDate={new Date()}
            selectsStart
            onClickOutside={() => setIsOpenStart(false)}
            onInputClick={() => setIsOpenStart(!isOpenStart)}
            shouldCloseOnSelect={false}
            startDate={startDate}
            endDate={endDate}
            open={isOpenStart}
            includeDateIntervals={includeDateIntervals}
            startDateClassName={startDate && !endDate ? "highlighted" : ""}
            endDateClassName={!startDate && endDate ? "highlighted" : ""}
            onFocus={(e) => e.target.blur()}
            disabledKeyboardNavigation
            placeholderText="mm/dd/yyyy"
          />
          <i
            className="fi flaticon-calendar"
            onClick={() => setIsOpenStart(!isOpenStart)}
          >
            {" "}
          </i>
        </div>

        <div className="input-group date">
          <DatePicker
            selected={endDateSelected ? endDate : startDate}
            onChange={(date) => ChangeEndDate(date)}
            minDate={new Date()}
            selectsEnd
            open={isOpenEnd}
            startDate={startDate}
            onClickOutside={() => setIsOpenEnd(false)}
            onInputClick={() => setIsOpenEnd(!isOpenStart)}
            shouldCloseOnSelect={false}
            endDate={endDate}
            minDate={startDate}
            includeDateIntervals={includeDateIntervals}
            startDateClassName={!startDate && endDate ? "highlighted" : ""}
            endDateClassName={startDate && !endDate ? "highlighted" : ""}
            onFocus={(e) => e.target.blur()}
            disabledKeyboardNavigation
            placeholderText="mm/dd/yyyy"
          />
          <i
            className="fi flaticon-calendar"
            onClick={() => setIsOpenEnd(!isOpenEnd)}
          ></i>
        </div>
        <div className={`tourist-dropdown  active`}>
          <div className="room-detail-search">
            <span>Adults</span>
            <div className="tourist-item-group">
              <button
                type="button"
                onClick={adultdecrementCount}
                id="adults_dec"
              >
                -
              </button>
              <input disabled id="adults_val" value={adult} type="text" />
              <button
                type="button"
                onClick={adultincrementCount}
                id="adults_inc"
              >
                +
              </button>
            </div>
          </div>
          <div className="room-detail-search">
            <span>Children</span>
            <div className="tourist-item-group">
              <button
                type="button"
                onClick={childdecrementCount}
                id="child_dec"
                disabled={room.maxOccupanciesChildren === 0}
              >
                -
              </button>
              <input disabled id="child_val" value={child} type="text" />
              <button
                type="button"
                onClick={childincrementCount}
                id="child_inc"
                disabled={room.maxOccupanciesChildren === 0}
              >
                +
              </button>
            </div>
          </div>
          <div className="room-detail-search">
            <span>Beds</span>
            <div className="tourist-item-group">
              <button type="button" onClick={beddecrementCount} id="room_dec">
                -
              </button>
              <input disabled id="room_val" value={bed} type="text" />
              <button type="button" onClick={bedincrementCount} id="room_inc">
                +
              </button>
            </div>
          </div>
        </div>
        <div className="input-group date">
          <button className="theme-btn" type="submit" onClick={Reserve}>
            Reserve
          </button>
        </div>
      </form>

      <div className="mt-10">
        <div className="text-center">
          <p>You won't be charged yet</p>
        </div>
        <div className="text-justify  d-flex justify-content-between">
          <p className="price-per-single">
            {" "}
            ${Math.round(room.pricePerNight)} x {nbofNights + " nights"}
          </p>
          <p className="price">
            {" "}
            ${Math.round(room.pricePerNight) * nbofNights}{" "}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CheckAvailabilitySideBar;
