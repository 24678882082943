import React from "react";
import { Link } from "react-router-dom";
import { Global } from "../../Global";

const ServiceSection2 = ({
  services,
  widgetTitle,
  widgetDescription,
  widgetUrl,
  page,
}) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <div className="wpo-service-area section-padding">

        <div className="wpo-service-wrap">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-4 col-lg-8 col-12">
                <div className="wpo-service-content">
                  <h2>{widgetTitle}</h2>
                  <p>{widgetDescription} </p>
                  <Link className="theme-btn" to={`/services`}>
                    Discover More
                  </Link>
                </div>
              </div>
              <div className="col-xl-8 col-12">
                <div className="wpo-service-items">
                  {services.map((service, index) => (
                    <div className="wpo-service-item" key={index}>
                      <img
                        src={Global.PHOTO_URL + service.roomServiceIcon}
                        alt=""
                      ></img>
                      <Link
                        onClick={ClickHandler}
                        to={`/services`}
                      >
                        {service.roomServiceName}
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
};

export default ServiceSection2;
