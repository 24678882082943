import { Global } from "../../Global";

const { createSlice } = require("@reduxjs/toolkit");

const menuSlice = createSlice({
  name: "menu",
  initialState: {
    menu: [],
  },
  reducers: {
    setMenu(state, action) {
      const items = action.payload.menu;

      return (state = {
        menu: items,
      });
    },
  },
});

export const GetMenu = () => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        Global.API_URL + "Client/Menu",{
          method:"GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "bs-api-key": Global.bs_api_key,
          }
        }
      );
      if (!response.ok) {
        throw new Error("Something went wrong!");
      }

      const result = await response.json();

      return result;
    } catch (error) {
    }
  };
};

export const { setMenu } = menuSlice.actions;
export default menuSlice.reducer;
