import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import SectionTitleS2 from "../SectionTitleS2";
import { Global } from "../../Global";

const BlogSection = ({ news, widgetTitle, blogClass ,page }) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <Fragment>
        <section className={`wpo-blog-section section-padding ${blogClass}`}>
          <div className="container">
            <SectionTitleS2
              backlight="back-dark"
              topTitle={"News"}
              MainTitle={widgetTitle}
            />
            <div className="wpo-blog-items">
              <div className="row">
                {news.map((newsItem, index) => (
                  <div className="col col-lg-4 col-md-6 col-12" key={index}>
                    <div className="wpo-blog-item">
                      <div className="wpo-blog-img">
                        <img
                          src={Global.PHOTO_URL + newsItem.newsImage}
                          alt=""
                        />
                      </div>
                      <div className="wpo-blog-content">
                        <div className="wpo-blog-content-top">
                          <div className="b-top">
                            <div className="b-top-inner">
                              <h2>
                                <Link
                                  onClick={ClickHandler}
                                  to={page}
                                >
                                  {newsItem.newsTitle}
                                </Link>
                              </h2>
                              <p>{newsItem.newsShortDescription}</p>
                            </div>
                          </div>
                          <Link
                            className="b-btn"
                            onClick={ClickHandler}
                            to={`/news/${newsItem.newsId}`}
                          >
                            Read More..
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
  
    </Fragment>
  );
};

export default BlogSection;
