import React, { Fragment, useEffect } from "react";
import PageTitle from "../../components/pagetitle/PageTitle";
import Navbar from "../../components/Navbar";
import OrderRecivedSec from "../../components/OrderRecivedSec";
import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";
import Logo from "../../images/Bustan-Al-Sukkar--Logo-Final-web-022.png";
import { useDispatch } from "react-redux";
import { resetBooking } from "../../store/slices/cart-slice";

const OrderRecived = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetBooking());
  }, [dispatch]);

  return (
    <Fragment>
      <Navbar hclass={"wpo-header-style-3"} Logo={Logo} />
      <PageTitle pageTitle={"Booking Details"} pagesub={"Booking Details"} />
      <OrderRecivedSec />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default OrderRecived;
