import React, { Fragment } from "react";
import PageTitle from "../../components/pagetitle/PageTitle";
import Navbar from "../../components/Navbar";
import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";
import Logo from "../../images/Bustan-Al-Sukkar--Logo-Final-web-022.png";
import Newslatter from "../../components/Newslatter/Newslatter";
import BookingDetail from "../../components/BookingDetail";

const BookingDetailPage = () => {
  return (
    <Fragment>
      <Navbar hclass={"wpo-header-style-3"} Logo={Logo} />
      <PageTitle pageTitle={"Booking Detail"} pagesub={""} />
      <BookingDetail />
      <Newslatter nClass={"section-bg"} />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default BookingDetailPage;
