import React, { Fragment, useEffect } from "react";
import PageTitle from "../../components/pagetitle/PageTitle";
import Navbar from "../../components/Navbar";
import CheckoutSection from "../../components/CheckoutSection";
import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";
import Logo from "../../images/Bustan-Al-Sukkar--Logo-Final-web-022.png";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const CheckoutPage = ({ cartList }) => {
  const navigate = useNavigate();
  const { roomId } = useSelector((state) => state.cart);

  useEffect(() => {
    if (roomId === 0) {
      navigate("/");
    }
  }, [navigate, roomId]);

  return (
    <Fragment>
      <Navbar hclass={"wpo-header-style-3"} Logo={Logo} />
      <PageTitle pageTitle={"Checkout"} pagesub={"Checkout"} />
      <CheckoutSection cartList={cartList} />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default CheckoutPage;
