import React, { Fragment, useState } from "react";
import Grid from "@material-ui/core/Grid";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Logo from "../../images/Bustan-Al-Sukkar--Logo-Final-web-022.png";
import "./style.scss";
import Navbar from "../../components/Navbar";
// import PageTitle from "../../components/pagetitle/PageTitle";
import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";
import { Global } from "../../Global";
import { useEffect } from "react";

const ChangePassword = () => {
  const push = useNavigate();
  const location = useLocation();
  const data = new URLSearchParams(location.search);
  const [value, setValue] = useState({
    password: "",
    confirmPassword: "",
  });
  const [disabled, setDisabled] = useState(false);

  const changeHandler = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
    validator.showMessages();
  };
  let strongPasswordRegex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&.?*])(?=.{8,})"
  );

  const [validator] = React.useState(
    new SimpleReactValidator({
      className: "errorMessage",
    })
  );

  const submitForm = async (e) => {
    e.preventDefault();
    if (!disabled) {
      if (!validator.allValid()) {
        validator.showMessages();
        toast.error("Empty field is not allowed!");
      } else if (!strongPasswordRegex.test(value.password)) {
        toast.error(
          "Password must be minimum 8 characters consisting of numbers, uppercase and lowercase letters and symbols"
        );
      } else if (value.password !== value.confirmPassword) {
        toast.error("Password doesn't match");
      } else {
        setDisabled(true);
        try {
          const response = await fetch(
            Global.API_URL +
              "Client/ChangePassword?Token=" +
              data.get("id") +
              "&Password=" +
              value.password,
            {
              method: "Post",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "bs-api-key": Global.bs_api_key,
              },
            }
          );
          const result = await response.json();
          if (response.ok) {
            if (result.code === 0) {
              toast.success(result.message);
              push("/login");
            } else {
              toast.error(result.message);
            }
          } else {
            toast.error(result.message);
          }
        } catch (ex) {
          console.log(ex.message);
        }
      }
    }
  };
  useEffect(() => {
    const CheckLink = async () => {
      const response = await fetch(
        Global.API_URL +
          "Client/ConfirmForgotPassword?Email=" +
          data.get("email").replaceAll("%20", "+") +
          "&Token=" +
          data.get("id").replaceAll("%20", "+"),
        {
          method: "Post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "bs-api-key": Global.bs_api_key,
          },
        }
      );
      const result = await response.json();
      if (response.ok) {
        if (result.code !== 0) {
          toast.error(result.message);
          push("/");
        }
      } else {
        if (result.code !== 0) {
          toast.error(result.message);
          push("/");
        }
      }
    };

    CheckLink();
  }, []);
  return (
    <Fragment>
      <Navbar hclass={"wpo-header-style-3"} Logo={Logo} />
      {/* <PageTitle pageTitle="" pagesub="" /> */}
      <section
        className="error-404-section section-padding-login"
        style={{
          background:
            "url('/images/Login-1.jpg') no-repeat center top/cover",
        }}
      >
        <div className="container">
          <Grid className="loginWrapper">
            <Grid className="loginForm">
              <h2>Forgot Password</h2>
              <p>Reset your account password</p>
              <form onSubmit={submitForm}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      className="inputOutline"
                      fullWidth
                      type="password"
                      placeholder="Password"
                      value={value.password}
                      variant="outlined"
                      name="password"
                      label="Password"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onBlur={(e) => changeHandler(e)}
                      onChange={(e) => changeHandler(e)}
                    />
                    {validator.message("password", value.password, "required")}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      className="inputOutline"
                      fullWidth
                      type="password"
                      placeholder="Confirm Password"
                      value={value.confirmPassword}
                      variant="outlined"
                      name="confirmPassword"
                      label="Confirm Password"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onBlur={(e) => changeHandler(e)}
                      onChange={(e) => changeHandler(e)}
                    />
                    {validator.message(
                      "Confirm Password",
                      value.confirmPassword,
                      "required"
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Grid className="formFooter">
                      <Button
                        fullWidth
                        className="cBtn cBtnLarge cBtnTheme"
                        type="submit"
                      >
                        Reset Password
                      </Button>
                    </Grid>
                    {/* <Grid className="loginWithSocial">
                    <Button className="facebook">
                      <i className="fa fa-facebook"></i>
                    </Button>
                    <Button className="twitter">
                      <i className="fa fa-twitter"></i>
                    </Button>
                    <Button className="linkedin">
                      <i className="fa fa-linkedin"></i>
                    </Button>
                  </Grid> */}
                    <p className="noteHelp">
                      Already have an account?{" "}
                      <Link to="/login">Return to Sign In</Link>
                    </p>
                  </Grid>
                </Grid>
              </form>
              <div className="shape-img">
                <i className="fi flaticon-honeycomb"></i>
              </div>
            </Grid>
          </Grid>
        </div>
      </section>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default ChangePassword;
