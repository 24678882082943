import React from 'react'

const SectionTitleS2 = (props) => {
    return(
        <div className="wpo-section-title-s2">
            <h2 className={props.backlight}>{props.MainTitle}</h2>
            <p className={props.backlight}>{props.description}</p>
        </div>

    )
}

export default SectionTitleS2;