import React from "react";
import Shape1 from "../../images/nshape1.png";
import Shape2 from "../../images/nshape2.png";
import { Global } from "../../Global";
import { useState } from "react";
import { toast } from "react-toastify";

const Newslatter = (props) => {
  const [email, setEmail] = useState("");
  const [disable, setDisable] = useState(false);
  const validEmail = email !== "" && email.includes("@") && email.includes(".");
  const SubmitHandler = async (e) => {
    e.preventDefault();
    if (validEmail && !disable) {
      setDisable(true);
      try {
        const response = await fetch(
          Global.API_URL + "Client/SubscribeNewsletter/" + email,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "bs-api-key": Global.bs_api_key,
            },
          }
        );
        if (response.ok) {
          let result = await response.json();
          if (result.code === 0) {
            setEmail("");
            toast.success(result.message);
            setDisable(false);
          } else {
            setDisable(false);
            toast.error(result.message);
          }
        }
      } catch (ex) {
        console.log(ex);
        setDisable(false);
      }
    } else {
      toast.error("Enter a valid email");
    }
  };

  return (
    <section
      className={`wpo-newslatter-section section-padding ${props.nClass}`}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-7">
            <div className="wpo-section-title-s2 ">
              <h2 className="back-light">Subscribe Now!</h2>
              <p className="back-light">
                  Subscribe To Stay Updated! Sign up now for our newsletter.
              </p>
            </div>
          </div>
        </div>
        <div className="wpo-newsletter">
          <div className="newsletter-form">
            <form onSubmit={SubmitHandler}>
              <input
                type="email"
                className="form-control"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter Your Email Address..."
                required
              />
              <button type="submit" disabled={disable}>
                Subscribe
              </button>
            </form>
          </div>
        </div>
      </div>
      <div className="n-shape">
        <img src={Shape1} alt="" />
      </div>
      <div className="n-shape2">
        <img src={Shape2} alt="" />
      </div>
    </section>
  );
};

export default Newslatter;
