import React from "react";
import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

const Testimonial = ({ reviews }) => {
  var settings = {
    dots: false,
    arrows: true,
    speed: 1200,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3500,
  };

  return (
    <div className="wpo-testimonial-area">
      <div className="container">
        <div className="wpo-testimonial-wrap">
          <div className="testimonial-slider">
            <Slider {...settings}>
              { reviews.map((review, index) => (
                    <div className="wpo-testimonial-item" key={index}>
                      <div className="wpo-testimonial-content">
                        <p>{review.ratingComment}</p>
                        <h2>{review.firstName + " " + review.lastName}</h2>
                        <span>
                          {review.isRegistered ? "Customer" : "Guest"}
                        </span>
                      </div>
                    </div>
                  ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
