import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Scrollbar from "../../components/scrollbar";
import Footer from "../../components/footer";
import Logo from "../../images/Bustan-Al-Sukkar--Logo-Final-web-022.png";
import Rooms from "./rooms";
import RoomDetails from "./RoomDetails";
import Newslatter from "../../components/Newslatter/Newslatter";
import { useLocation } from "react-router-dom";

import { useSelector } from "react-redux";
import { Global } from "../../Global";

const RoomSinglePage = (props) => {
  const { id } = useParams();
  const location = useLocation();
  const { customerToken, token } = useSelector((state) => state.credential);
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + 5);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [contactUs, setContactUs] = useState({
    title: "",
    body: "",
  });

  const [room, setRoom] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const [child, setChild] = useState(0);
  const [adult, setAdult] = useState(1);
  const [bed, setBed] = useState(1);

  function getDaysArray(startDate, endDate) {
    const daysArray = [];
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      daysArray.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return daysArray;
  }

  // function findFirstUnbookedDate(dateRanges) {
  //   // Create an array to store all dates
  //   let allDates = [];

  //   // Sort date ranges by start date
  //   dateRanges.sort((a, b) => {
  //     return new Date(a.end) - new Date(b.end)
  //   });

  //   // Initialize a variable to track the current date
  //   let currentDate = new Date();

  //   dateRanges.forEach(range => {
  //     const startDate = new Date(range.start);
  //     const endDate = new Date(range.end);

  //     // Add dates between the current date and the start date of the range
  //     while (currentDate < startDate) {
  //       allDates.push(new Date(currentDate));
  //       currentDate.setDate(currentDate.getDate() + 1);
  //     }

  //     // Move the current date to the day after the end date of the range
  //     currentDate = new Date(endDate);
  //     currentDate.setDate(currentDate.getDate() + 1);
  //   });

  //   // Add any remaining dates after the last range
  //   while (currentDate <= new Date(dateRanges[dateRanges.length - 1].end)) {
  //     allDates.push(new Date(currentDate));
  //     currentDate.setDate(currentDate.getDate() + 1);
  //   }

  //   return allDates;
  // }
  useEffect(() => {
    window.scrollTo(0, 0);
    const params = new URLSearchParams(location.search);
    let CheckInDate = new Date();
    if (params.get("CheckIn")) {
      CheckInDate =new Date( params.get("CheckIn").trim());
      setStartDate(CheckInDate);
      setEndDate(new Date(params.get("CheckOut").trim()));
      setBed(params.get("beds").trim());
      setChild(params.get("children").trim());
      setAdult(params.get("adults").trim());
    }
    const GetRoom = async () => {
      let Token;
      if (customerToken && customerToken !== "") {
        Token = customerToken;

        const response = await fetch(
          Global.API_URL +
            "Client/GetRoomById/" +
            id +
            "?StartDate=" +
            CheckInDate.toISOString(),
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "bs-api-key": Global.bs_api_key,
              AuthorizationCustomer: Token,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Something went wrong!");
        }
        if (response.ok) {
          const result = await response.json();

          result.message.bookedDates.forEach((book) => {
            book.start = new Date(book.start);
            book.end = new Date(book.end);
          });
          setRoom(result.message);
          setIsLoading(false);
        }
      } else if (token && token !== "") {
        Token = token;

        const response = await fetch(
          Global.API_URL +
            "Client/GetRoomById/" +
            id +
            "?StartDate=" +
           CheckInDate.toISOString(),
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "bs-api-key": Global.bs_api_key,
              AuthorizationGuest: Token,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Something went wrong!");
        }
        if (response.ok) {
          const result = await response.json();
          // const firstUnbookedDate = findFirstUnbookedDate(result.message.bookedDates);

          // if (firstUnbookedDate) {
          //   setEndDate(firstUnbookedDate[0])
          // }
          result.message.bookedDates.forEach((book) => {
            book.start = new Date(book.start);
            book.end = new Date(book.end);
          });
          setRoom(result.message);
          setIsLoading(false);
        }
      }

      const response = await fetch(
        Global.API_URL + "ContactUs/GetContactUs?contactusid=1",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "bs-api-key": Global.bs_api_key,
          },
        }
      );
      const result = await response.json();
      if (result.code === 0) {
        setContactUs(result.message);
      }
    };

    GetRoom();
  }, [customerToken, id, token]);

  return (
    <Fragment>
      <Navbar hclass="wpo-header-style-3" Logo={Logo} />
      <div className="room-details-section">
        {!isLoading ? (
          <div className="room-details-inner">
            <Rooms images={room.images} />
            <RoomDetails
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              room={room}
              setRoom={setRoom}
              contactUs={contactUs}
              beds={bed}
              adults={adult}
              children={child}
            />
          </div>
        ) : (
          <div className="container">
            <div className="row align-items-center">
              {[1, 2, 3, 4].map((item) => (
                <div className="col-xl-3 col-12" key={item}>
                  <div className="wpo-service-items wpo-service-active">
                    <div
                      className="skeleton-box-product skeleton-product"
                      key={item}
                    ></div>
                  </div>
                </div>
              ))}
            </div>
            <div className="row align-items-center mt-100">
              <div className="col-xl-9 col-12">
                <div className="skeleton-loader">
                  <div className="skeleton-title"></div>
                  <hr />
                  <div className="skeleton-paragraph"></div>
                  <div className="skeleton-paragraph"></div>
                  <div className="skeleton-paragraph"></div>
                  <div className="skeleton-paragraph"></div>
                  <div className="skeleton-paragraph"></div>
                  <div className="skeleton-paragraph"></div>
                  <div className="skeleton-paragraph"></div>
                </div>
              </div>
              <div className="col-xl-3 col-12">
                <div className="card skeleton">
                  <div className="skeleton-input"></div>
                  <div className="skeleton-input"></div>
                  <div className="skeleton-input"></div>
                  <div className="skeleton-button"></div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Newslatter nclassName={"section-bg"} />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default RoomSinglePage;
