import React, { useEffect, useState, Fragment } from "react";
import { Global } from "../../Global";
import PageTitle from "../../components/pagetitle/PageTitle";
import Navbar from "../../components/Navbar";
import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";
import Logo from "../../images/Bustan-Al-Sukkar--Logo-Final-web-022.png";
import Newslatter from "../../components/Newslatter/Newslatter";

const TermsAndConditions = () => {
  const [widgets, setWidgets] = useState([]);

  const GetWidgetByPageID = async (Skip) => {
    try {
      const response = await fetch(
        Global.API_URL +
          "Client/GetDynamicWidgetsByPageId?pageid=2&Skip=" +
          Skip +
          "&Take=1",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "bs-api-key": Global.bs_api_key,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Something went wrong!");
      }
      if (response.ok) {
        const result = await response.json();
        setWidgets(result.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    GetWidgetByPageID(0, []);
  }, []);
  return (
    <Fragment>
      {widgets && widgets.length > 0 ? (
        <Fragment>
          <Navbar hclass={"wpo-header-style-3"} Logo={Logo} />
          <PageTitle
            pageTitle={widgets[0].widgetTitle}
            pagesub={widgets[0].widgetTitle}
          />

          <div
            className="term-condition"
            dangerouslySetInnerHTML={{
              __html: widgets[0].content.contentDescription,
            }}
          />
          <Newslatter nClass={"section-bg"} />
          <Footer />
          <Scrollbar />
        </Fragment>
      ) : (
        <div className="egns-preloader">
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-6">
                <div className="circle-border">
                  <div className="moving-circle"></div>
                  <div className="moving-circle"></div>
                  <div className="moving-circle"></div>
                  <svg
                    width="180px"
                    height="150px"
                    viewBox="0 0 187.3 93.7"
                    preserveAspectRatio="xMidYMid meet"
                  >
                    <path
                      stroke="#df865d"
                      id="outline"
                      fill="none"
                      strokeWidth="4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeMiterlimit="10"
                      d="M93.9,46.4c9.3,9.5,13.8,17.9,23.5,17.9s17.5-7.8,17.5-17.5s-7.8-17.6-17.5-17.5c-9.7,0.1-13.3,7.2-22.1,17.1 c-8.9,8.8-15.7,17.9-25.4,17.9s-17.5-7.8-17.5-17.5s7.8-17.5,17.5-17.5S86.2,38.6,93.9,46.4z"
                    ></path>
                    <path
                      id="outline-bg"
                      opacity="0.05"
                      fill="none"
                      stroke="#df865d"
                      strokeWidth="4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeMiterlimit="10"
                      d="M93.9,46.4c9.3,9.5,13.8,17.9,23.5,17.9s17.5-7.8,17.5-17.5s-7.8-17.6-17.5-17.5c-9.7,0.1-13.3,7.2-22.1,17.1 c-8.9,8.8-15.7,17.9-25.4,17.9s-17.5-7.8-17.5-17.5s7.8-17.5,17.5-17.5S86.2,38.6,93.9,46.4z"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default TermsAndConditions;
