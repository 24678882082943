import React, { useEffect, useState, useRef, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Global } from "../../Global";

const Rooms2 = () => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };
  const navigate = useNavigate();
  const productRef = useRef();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(1);
  const [count, setCount] = useState(0);
const GetRooms = async (Skip, Array)=>{
  setIsFetching(1);
  let newArray = [];
  try {
    const response = await fetch(
      Global.API_URL +
        "Client/GetRooms?Skip=" +
        Skip +
        "&Take=10",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "bs-api-key": Global.bs_api_key,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Something went wrong!");
    }
    if (response.ok) {
      const result = await response.json();

      if (result.message.length !== 0) {
        setIsFetching(0);
      }
      if (Skip === 0) {
        newArray = result.message;
      } else {
        newArray = Array.concat(result.message);
      }
      setProducts(newArray);
      setCount(count + 10);
      setLoading(false);
    }
  } catch (error) {
    console.log(error);
  }
}

  useEffect(() => {
    const GetRooms = async () => {
      try {
        const response = await fetch(Global.API_URL + "Client/GetRooms?Skip=0&Take=10", {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "bs-api-key": Global.bs_api_key,
          },
        });

        if (!response.ok) {
          setLoading(false);
          throw new Error("Something went wrong!");
        }

        const result = await response.json();
        setProducts(result.message);
        setTimeout(() => {
          setLoading(false);
        }, [700]);
      } catch (error) {}
    };
    GetRooms();
  }, []);
  window.onscroll = function () {
    if (productRef.current && isFetching === 0) {
      GetRooms(count, products);
    }
  };

  return (
    <div className="wpo-room-area section-bg section-padding" ref={productRef}>
      <div className="container">
        <div className="room-wrap">
          <div className="row">
            {loading ? (
              [1, 2, 3, 4].map((item) => (
                <div className="col-lg-3 col-md-6 col-12 margintop-50" key={item}>
                  <div className="skeleton-loading">
                    <div className="skeleton-image"></div>
                    <div className="skeleton-text"></div>
                    <div className="skeleton-text second-text"></div>
                    <div className="skeleton-text"></div>
                    <div className="skeleton-button"></div>
                  </div>
                </div>
              ))
            ) : products.length > 0 ? (
              products.map((product, pitem) => (
                <div
                  className="col-lg-3 col-md-6 col-12"
                  key={pitem}
                  onClick={()=>navigate(`/room-single/${product.roomId}`)}
                >
                  <div className="room-counts">{pitem + 1} out of {products.length} </div>
                  <div className="room-item">
                    <div className="room-img">
                      <img src={Global.PHOTO_URL + product.imagePath} alt="" />
                    </div>
                    <div className="room-content">
                      <h2>
                        <Link
                          onClick={ClickHandler}
                          to={`/room-single/${product.roomId}`}
                        >
                          {product.roomName}
                        </Link>
                      </h2>
                      <ul>
                        <li>
                          <i className="fi flaticon-expand-arrows"></i>
                          {product.totalNbOfBedrooms} Bedrooms
                        </li>
                        <li>
                          <i className="fi flaticon-bed"></i>
                          {product.totalNbOfBeds} Bed
                        </li>
                        {/* <li>
                          <i className="fi flaticon-bathtub"></i>
                          {product.nbOfBaths} Bathroom
                        </li> */}
                      </ul>
                      <h3>
                      {product.pricePerWeekday ===
                        product.pricePerWeekend ? (
                          "$" + product.pricePerWeekend
                        ) : (
                          <Fragment>
                            ${product.pricePerWeekday} - $
                            {product.pricePerWeekend}
                          </Fragment>
                        )}
                      </h3>
                      <div className="add-to-cart">
                        <button
                          className="theme-btn mt-3"
                          data-bs-toggle="tooltip"
                          data-bs-html="true"
                          title="Add to Cart"
                          onClick={() =>
                            navigate(`/room-single/${product.roomId}`)
                          }
                        >
                          Select this room
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="wpo-reservation-area">
                <div className="container">
                  <div className="wpo-reservation-wrap">
                    <div className="reservation-slider">
                      <div className="container-reservation">
                        <img
                          src="/images/villa-100.png"
                          alt="reservation"
                          className="icon"
                        />
                        <p className="text-icon"> No Villas found</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rooms2;
