import React from "react";
import { Link } from "react-router-dom";
import { Global } from "../../Global";
import { Fragment } from "react";

const Banner = ({
  banner,
  widgetTitle,
  widgetDescription,
  widgetUrl
}) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <Fragment>

        <section
          className="wpo-about-section-s2 section-padding"
          style={{
            background: `url(${
              Global.PHOTO_URL + banner.bannerImage
            }) no-repeat center center`,
            backgroundSize: `cover`,
          }}
        >
          <div className="container-fluid">
            <div className="wpo-about-section-wrapper">
              <div className="row align-items-center">
                <div className="col-xl-5 col-lg-6 col-md-8 col-12 offset-xl-6 offset-lg-4 offset-md-2">
                  <div className="wpo-about-content">
                    <div className="about-title">
                      <span>{widgetTitle}</span>
                      <p>{widgetDescription}</p>
                    </div>
                    {widgetUrl !== "" && (
                      <div className="wpo-about-content-inner">
                        <div className="about-info-wrap">
                          <Link
                            onClick={ClickHandler}
                            className="theme-btn"
                            to={widgetUrl}
                          >
                            Show More
                          </Link>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      
    </Fragment>
  );
};

export default Banner;
