import React, { useState  } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";

const SearchSection = (props) => {
  const navigate = useNavigate();
  const [isOpenStart,setIsOpenStart]=useState(false)
  const [isOpenEnd,setIsOpenEnd]=useState(false)
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setendDate] = useState(new Date());

  const [searchActive, setsearchState] = useState(false);

  const SubmitHandler = (e) => {
    e.preventDefault();
  };


  const ClickHandler = async () => {
    navigate(
      "/search-result?CheckIn=" +
        startDate.toISOString() +
        "&CheckOut=" +
        endDate.toISOString() +
        "&adults=" +
        adult +
        "&children=" +
        child +
        "&beds=" +
        room
    );
  };

  const [adult, setCount] = useState(1);
  const adultincrementCount = () => {
    if (adult < 2) {
      setCount(adult + 1);
    }
  };
  const adultdecrementCount = () => {
    if (adult > 1) {
      setCount(adult - 1);
    }
  };

  const [child, setChild] = useState(0);
  const childincrementCount = () => {
    if (child < 2) {
      setChild(child + 1);
    }
  };
  const childdecrementCount = () => {
    if (child > 0) {
      setChild(child - 1);
    }
  };
  const [room, setroom] = useState(1);
  const roomincrementCount = () => {
    if (room < 2) {
      setroom(room + 1);
    }
  };
  const roomdecrementCount = () => {
    if (room > 1) {
      setroom(room - 1);
    }
  };
  return (
    <div className={`wpo-select-section ${props.svClass}`}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="wpo-select-wrap">
              <div className="wpo-select-area">
                <form className="clearfix" onSubmit={SubmitHandler}>
                  <div className="select-sub">
                    <div className="input-group date" >
                      <DatePicker
                        selected={startDate}
                        minDate={new Date()}
                        showIcon={false}
                        disabledKeyboardNavigation
                        onClickOutside={()=>setIsOpenStart(false)}
                        onChange={(date) => {setStartDate(date);setIsOpenStart(!isOpenStart)}}
                        open={isOpenStart}
                        onInputClick={()=>setIsOpenStart(!isOpenStart)}
                        shouldCloseOnSelect={false}
                        onFocus={(e) => {
                          e.target.blur();
                          setsearchState(false);
                        }}
                      />
                      <i className="fi flaticon-calendar" onClick={()=>setIsOpenStart(!isOpenStart)}></i>
                    </div>
                  </div>
                  <div className="select-sub">
                    <div className="input-group date" >
                      <DatePicker
                        selected={endDate}
                        minDate={new Date()}
                        disabledKeyboardNavigation
                        onClickOutside={()=>setIsOpenEnd(false)}
                        onChange={(date) => {setendDate(date);setIsOpenEnd(!isOpenEnd)}}
                        open={isOpenEnd}
                        onInputClick={()=>setIsOpenEnd(!isOpenEnd)}
                        shouldCloseOnSelect={false}
                        onFocus={(e) => {
                          e.target.blur();
                          setsearchState(false);
                        }}
                      />
                      <i className="fi flaticon-calendar" onClick={()=>setIsOpenEnd(!isOpenEnd)}></i>
                    </div>
                  </div>
                  <div className="select-sub">
                    <div className="form-group tourist-group">
                      <div className="tourist-group-wrap">
                        <div
                          className="tourist-inner"
                          onClick={() => setsearchState(!searchActive)}
                        >
                          <i className="fi flaticon-user"></i>
                          <ul>
                            <li>
                              <input
                                disabled
                                type="text"
                                id="adults"
                                value={adult}
                              />
                              Adults
                            </li>
                            <li>
                              <input
                                disabled
                                type="text"
                                id="children"
                                value={child}
                              />
                              Children
                            </li>
                            <li>
                              <input
                                disabled
                                type="text"
                                id="rooms"
                                value={room}
                              />{" "}
                              Beds
                            </li>
                          </ul>
                          <i
                            className={`ti-angle-down ${
                              searchActive ? "rotate" : ""
                            }`}
                          ></i>
                        </div>
                        <div
                          className={`tourist-dropdown ${
                            searchActive ? "active" : ""
                          }`}
                        >
                          <span
                            className="close-icon"
                            onClick={() => setsearchState(false)}
                          >
                            &times;
                          </span>
                          <div className="tourist-item">
                            <span>Adults</span>
                            <div className="tourist-item-group">
                              <button
                                type="button"
                                onClick={adultdecrementCount}
                                id="adults_dec"
                              >
                                -
                              </button>
                              <input
                                disabled
                                id="adults_val"
                                value={adult}
                                type="text"
                              />
                              <button
                                type="button"
                                onClick={adultincrementCount}
                                id="adults_inc"
                              >
                                +
                              </button>
                            </div>
                          </div>
                          <div className="tourist-item">
                            <span>Children</span>
                            <div className="tourist-item-group">
                              <button
                                type="button"
                                onClick={childdecrementCount}
                                id="children_dec"
                              >
                                -
                              </button>
                              <input
                                disabled
                                id="children_val"
                                value={child}
                                type="text"
                              />
                              <button
                                type="button"
                                onClick={childincrementCount}
                                id="children_inc"
                              >
                                +
                              </button>
                            </div>
                          </div>
                          <div className="tourist-item">
                            <span>Beds</span>
                            <div className="tourist-item-group">
                              <button
                                type="button"
                                onClick={roomdecrementCount}
                                id="rooms_dec"
                              >
                                -
                              </button>
                              <input
                                disabled
                                id="rooms_val"
                                value={room}
                                type="text"
                              />
                              <button
                                type="button"
                                onClick={roomincrementCount}
                                id="rooms_inc"
                              >
                                +
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="select-sub">
                    <button onClick={ClickHandler} className="theme-btn">
                      Check Availability
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchSection;
