import React from 'react'
import { Link ,useNavigate} from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { GenerateGuest, setCredential } from '../../store/slices/credential-slice';

const ProfileSideBar = ({page,setPage , fetchBookingHistory}) => {
    const dispatch=useDispatch()
    const navigate=useNavigate()
    const ClickHandler = (value) => {
        window.scrollTo(10, 0);
        
        if (value === 4) {
            let result;
            let promise;
            result = dispatch(GenerateGuest());
            promise = Promise.resolve(result);
            promise.then((value) => {
              dispatch(
                setCredential({
                  isLogged: false,
                  guestId: value.message.guestId,
                  token: value.message.token,
                  customerId: 0,
                  customerToken: "",
                  firstName: "",
                  lastName: "",
                  email: "",
                })
              );
            });
          navigate("/")
        }else if(value === 3 ){
            setPage(value);
            fetchBookingHistory()
        } else {
          setPage(value);
        }
    }

    return (
        <div className="col-lg-4 col-md-8">
            <div className="wpo-single-sidebar">
                <div className="wpo-service-widget widget">
                  
                    <ul>
                    <li ><Link onClick={()=>ClickHandler(1)} className={parseInt(page) === 1 ? "active-page" : ""} to="/my-account" >Personal Detail</Link></li>
                    <li ><Link onClick={()=>ClickHandler(2)} className={parseInt(page) === 2 ? "active-page" : ""} to="/my-account" >Security</Link></li>
                    <li><Link onClick={()=>ClickHandler(3)} className={parseInt(page) === 3 ? "active-page" : ""} to="/my-account" >Reservation History</Link></li>
                    <li><Link onClick={()=>ClickHandler(4)} className={parseInt(page) === 4 ? "active-page" : ""} to="/" >Sign Out</Link></li>
                    </ul>
                </div>
            </div>
        </div>

    )
}

export default ProfileSideBar;