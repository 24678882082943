const { createSlice } = require("@reduxjs/toolkit");

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    details: {
      roomId: 0,
      roomName: "",
      isBreakfastIncluded: false,
      breakfastPrice: 0,
      checkinDate: "",
      checkoutDate: "",
      pricePerNight: 0,
      nbofNights: 0,
    },
  },
  reducers: {
    setDetail(state, action) {
      return (state = {
        details: action.payload,
      });
    },
    resetBooking(state, action) {
      state.details.roomId = 0;
    },
  },
});

export const { setDetail, resetBooking } = cartSlice.actions;
export default cartSlice.reducer;
