import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import RoomSidebar from "./RoomSidebar";
import { Global } from "../../Global";
import CheckAvailabilitySideBar from "./CheckAvailabilitySideBar";
import RoomReviews from "./RoomReviews";
import { RWebShare } from "react-web-share";

const RoomDetails = ({
  startDate,
  setEndDate,
  endDate,
  setStartDate,
  room,
  setRoom,
  contactUs,
  beds,
  adults,
  children,
}) => {
  
  return (
    <div className="Room-details-area pb-120">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-12">
            <div className="blog-sidebar room-sidebar mobile-side-bar">
              <CheckAvailabilitySideBar
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                room={room}
                setRoom={setRoom}
                beds={beds}
                adults={adults}
                children={children}
              />
              <div className="wpo-single-sidebar"></div>
            </div>
            <div className="room-description">
              <div className="room-title">
                <h2>
                  {room.roomName}{" "}
                  <RWebShare
                    data={{
                      text: "",
                      url: window.location.href,
                      title: "Bustan Al Sukkar",
                    }}
                    onClick={() => console.log("shared successfully!")}
                  >
                    <i
                      className="fa fa-share-alt"
                      aria-hidden="true"
                      onClick={() =>
                        navigator.clipboard.writeText(window.location.href)
                      }
                    ></i>
                    {/* <button>Share 🔗</button> */}
                  </RWebShare>
                </h2>
              </div>
              {/* <p className="p-wrap">{room.shortDescription}</p> */}
              <p>{room.description}</p>
            </div>
            <div className="room-details-service">
              <div className="row">
                <div className="room-details-item">
                  <div className="row">
                    <div className="col-md-5 col-sm-5">
                      <div className="room-d-text">
                        <div className="room-title">
                          <h2>Room Services</h2>
                        </div>
                        <ul>
                          {room.services.map((service, index) => (
                            <li key={index}>
                              <Link to="/room-single/1">
                                {service.serviceName}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    {room.services.length > 0 && (
                      <div className="col-md-7 col-sm-7">
                        <div className="room-d-img">
                          <img
                            src={
                              Global.PHOTO_URL + room.services[0].serviceImage
                            }
                            alt=""
                          />
                        </div>
                      </div>
                    )}
                    {room.items.length > 0 && (
                      <Fragment>
                        <div className="col-md-7 col-sm-7">
                          <div className="room-d-img">
                            <img
                              src={Global.PHOTO_URL + room.items[0].itemImage}
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="col-md-5 col-sm-5">
                          <div className="room-d-text2">
                            <div className="room-title">
                              <h2>Allowed Items</h2>
                            </div>
                            <ul>
                              {room.items.map((item, index) => (
                                <li key={index}>
                                  <Link to="/room-single/1">
                                    {item.itemName}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </Fragment>
                    )}

                    {room.notAllowedItems.length > 0 && (
                      <Fragment>
                        <div className="col-md-5 col-sm-5">
                          <div className="room-d-text">
                            <div className="room-title">
                              <h2>Room Not Allowed Items</h2>
                            </div>
                            <ul>
                              {room.notAllowedItems.map(
                                (notAllowedItem, index) => (
                                  <li key={index}>
                                    <Link to="/room-single/1">
                                      {notAllowedItem.itemName}
                                    </Link>
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                        </div>
                        {room.notAllowedItems.length > 0 && (
                          <div className="col-md-7 col-sm-7">
                            <div className="room-d-img">
                              <img
                                src={
                                  Global.PHOTO_URL +
                                  room.notAllowedItems[0].itemImage
                                }
                                alt=""
                              />
                            </div>
                          </div>
                        )}
                      </Fragment>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="desktop-side-bar">
              <RoomReviews room={room} setRoom={setRoom} />
            </div>
          </div>
          <RoomSidebar
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            room={room}
            setRoom={setRoom}
            contactUs={contactUs}
            beds={beds}
            adults={adults}
            children={children}
          />
        </div>
      </div>
    </div>
  );
};

export default RoomDetails;
