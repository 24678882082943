import React, { Fragment, useEffect, useState } from "react";
import PageTitle from "../../components/pagetitle/PageTitle";
import Navbar from "../../components/Navbar";
import SearchRooms from "../../components/SearchRooms/SearchRooms";
import Scrollbar from "../../components/scrollbar";
import Logo from "../../images/Bustan-Al-Sukkar--Logo-Final-web-022.png";
import Footer from "../../components/footer";
import { Global } from "../../Global";
import { useLocation } from "react-router-dom";

const SearchResults = () => {
  const location = useLocation();

  const addToCartProduct = (product, qty = 1) => {};

  const [products, setProducts] = useState([]);
  const [searchTitle, setSearchTitle] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const [checkIn, setCheckIn] = useState("");
  const [checkOut, setCheckOut] = useState("");
  const [adult, setAdult] = useState("");
  const [child, setChild] = useState("");
  const [bed, setBed] = useState("");

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setIsLoading(true);
    if (params.get("CheckIn")) {
      const CheckIn = params.get("CheckIn");
      const CheckOut = params.get("CheckOut");
      const adults = params.get("adults");
      const children = params.get("children");
      const beds = params.get("beds");
      setCheckIn(CheckIn);
      setCheckOut(CheckOut);
      setAdult(adults);
      setChild(children);
      setBed(beds);
      const CheckAvailabilty = async () => {
        try {
          const response = await fetch(
            Global.API_URL + "Client/AvailibilityCheck",
            {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "bs-api-key": Global.bs_api_key,
              },
              body: JSON.stringify({
                startDate: CheckIn,
                endDate: CheckOut,
                nbOfAdults: adults,
                nbOfChildren: children,
                nbOfBedRooms: beds,
              }),
            }
          );

          if (!response.ok) {
            throw new Error("Something went wrong!");
          }
          if (response.ok) {
            const result = await response.json();
            setProducts(result.rooms);
            setSearchTitle(result.searchTitle);
            setIsLoading(false);
          }
        } catch (error) {}
      };
      CheckAvailabilty();
    } else {
      const keyword = params.get("keyword");

      const Search = async () => {
        try {
          const response = await fetch(
            Global.API_URL + "Client/KeywordSearch/" + keyword,
            {
              method: "Get",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "bs-api-key": Global.bs_api_key,
              },
            }
          );

          if (!response.ok) {
            throw new Error("Something went wrong!");
          }
          if (response.ok) {
            const result = await response.json();
            setProducts(result.rooms);
            setSearchTitle(result.searchTitle);
            setIsLoading(false);
          }
        } catch (error) {}
      };
      Search();
    }
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <Fragment>
      <Navbar hclass={"wpo-header-style-3"} Logo={Logo} />
      <PageTitle pageTitle={searchTitle} pagesub={"Search"} />
      <section className="wpo-shop-page">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <SearchRooms
                addToCartProduct={addToCartProduct}
                products={products}
                isLoading={isLoading}
                CheckIn={checkIn}
                CheckOut={checkOut}
                adults={adult}
                children={child}
                beds={bed}
              />
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default SearchResults;
