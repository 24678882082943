import React, { Fragment } from "react";
import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

var settings = {
  dots: false,
  arrows: true,
  speed: 1200,
  slidesToShow: 4,
  autoplay: true,
  autoplaySpeed: 3500,
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
var settingsTestimonial = {
  dots: false,
  arrows: true,
  speed: 1200,
  fade: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3500,
};

const Skeleton = () => {
  return (
    <Fragment>
      <div className="wpo-room-area section-padding">
        <div className="container">
          <div className="row">
            <div className="col-4"></div>
            <div className="col-4 skeleton-main">
              <div className="skeleton-loading-title"></div>
              <br />
              <div className="skeleton-loading-subtitle"></div>
            </div>
            <div className="col-4"></div>
            {[1, 2, 3, 4].map((item) => (
              <div className="col-lg-3 col-md-6 col-12" key={item}>
                <div className="skeleton-loading">
                  <div className="skeleton-image"></div>
                  <div className="skeleton-text"></div>
                  <div className="skeleton-text second-text"></div>
                  <div className="skeleton-text"></div>
                  <div className="skeleton-button"></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={`wpo-service-area-s2 space-section`}>
        <div className="wpo-service-wrap">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-xl-12 col-12">
                <div className="wpo-service-items wpo-service-active">
                  <Slider {...settings}>
                    {[1, 2, 3, 4, 5].map((item) => (
                      <div className="skeleton-box" key={item}>
                        <div className="skeleton-small-box">
                          <div className="skeleton-text"></div>
                          <div className="skeleton-text"></div>
                          <div className="skeleton-text-small"></div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="wpo-service-area section-padding space-section">
        <div className="container container-card">
          <div className="row align-items-center">
            <div className="col-xl-4 col-lg-8 col-12">
              <div className="wpo-service-content">
                <div className="left-side">
                  <div className="skeleton-text"></div>
                  <div className="skeleton-text"></div>
                  <div className="skeleton-text"></div>
                  <div className="skeleton-text"></div>
                  <div className="skeleton-button"></div>
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-12">
              <div className="wpo-service-items">
                <div className="right-side">
                  <div className="grid">
                    <div className="small-card skeleton"></div>
                    <div className="small-card skeleton"></div>
                    <div className="small-card skeleton"></div>
                    <div className="small-card skeleton"></div>
                    <div className="small-card skeleton"></div>
                    <div className="small-card skeleton"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="big-card">
        <div className="container-fluid">
          <div className="wpo-about-section-wrapper">
            <div className="row align-items-center">
              <div className="col-xl-5 col-lg-6 col-md-8 col-12 offset-xl-6 offset-lg-4 offset-md-2">
                <div className="wpo-about-content small-card">
                  <div className="skeleton-text"></div>
                  <div className="skeleton-text"></div>
                  <div className="skeleton-text"></div>
                  <div className="skeleton-text"></div>
                  <div className="skeleton-text"></div>
                  <div className="skeleton-text"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="wpo-about-section section-padding">
        <div className="container">
          <div className="wpo-about-section-wrapper">
            <div className="row align-items-center skeleton-container">
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-img">
                  <div className="skeleton-image"></div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-content">
                  <div className="skeleton-content">
                    <div className="skeleton-title"></div>
                    <div className="skeleton-text"></div>
                    <div className="skeleton-text"></div>
                    <div className="skeleton-text"></div>
                    <div className="skeleton-text"></div>
                    <div className="skeleton-text"></div>
                    <div className="skeleton-text"></div>
                    <div className="skeleton-text"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="wpo-testimonial-area section-padding">
        <div className="container">
          <div className="wpo-testimonial-wrap">
            <div className="testimonial-slider">
              <Slider {...settingsTestimonial}>
                {[1, 2, 3, 4, 5].map((item) => (
                  <div className="testimonial-card" key={item}>
                    <div className="skeleton-quote"></div>
                    <div className="skeleton-content">
                      <div className="skeleton-text"></div>
                      <div className="skeleton-text"></div>
                      <div className="skeleton-text-last"></div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Skeleton;
