import { Global } from "../../Global";

const { createSlice } = require("@reduxjs/toolkit");

const widgetSlice = createSlice({
  name: "widget",
  initialState: {
    slides: [],
  },
  reducers: {
    setSlides(state, action) {
      state.slides = action.payload.slides;
    },
    clearSlides(state, action) {
      return (state = {
        slides: [],
      });
    },
  },
});


export const getSlides = () => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        Global.API_URL + "Client/Slides",{
          method:"GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "bs-api-key": Global.bs_api_key,
          }
        }
      );

      if (!response.ok) {
        throw new Error("Something went wrong!");
      }

      const result = await response.json();
      return result;
    } catch (error) {

    }
  };
};

export const { setSlides, clearSlides, setContents } = widgetSlice.actions;
export default widgetSlice.reducer;
