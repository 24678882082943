import React, { Fragment, useState, useEffect } from "react";
import PageTitle from "../../components/pagetitle/PageTitle";
import Navbar from "../../components/Navbar";
import Logo from "../../images/Bustan-Al-Sukkar--Logo-Final-web-022.png";
import Newslatter from "../../components/Newslatter/Newslatter";
import Scrollbar from "../../components/scrollbar";
import { useParams } from "react-router-dom";
import BlogSingle from "../../components/BlogDetails";
import Footer from "../../components/footer";
import { Global } from "../../Global";

const BlogDetails = () => {
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [news, setNews] = useState();

  useEffect(() => {
    const GetNews = async () => {
      const response = await fetch(
        Global.API_URL + "News/GetNewsById?newsid=" + id,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "bs-api-key": Global.bs_api_key,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Something went wrong!");
      }
      if (response.ok) {
        const result = await response.json();
        setNews(result.message);
        setIsLoading(false);
      }
    };

    GetNews();
  }, [id]);

  return (
    <Fragment>
      <Navbar hclass={"wpo-header-style-3"} Logo={Logo} />
      <PageTitle pageTitle={news && news.newsTitle} pagesub={"News"} />
      <BlogSingle news={news} isLoading={isLoading} />
      <Newslatter nClass={"section-bg"} />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default BlogDetails;
