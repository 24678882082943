import React , { useEffect , useState} from "react";
import { Link } from "react-router-dom";
import Logo from "../../images/Bustan-Al-Sukkar--Logo-Final-web-012.png";
import { useSelector } from "react-redux";
import {Global} from "../../Global"

const Footer = (props) => {
  const menu = useSelector((state) => state.menu.menu);
  const [services,setServices]=useState([]);
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };
   useEffect(()=>{
    const GetServices = async ()=>{
      try {
        const response = await fetch(
          Global.API_URL + "RoomService/GetRoomServicesDropdownList" ,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "bs-api-key": Global.bs_api_key,
            },
          }
        );
        const result = await response.json();
        if (result.code === 0) {
          setServices(result.message)
        }
      } catch (error) {
        console.log(error);
      }
    }
    GetServices()
   },[])
  return (
    <footer className="wpo-site-footer">
      <div className="wpo-upper-footer">
        <div className="container">
          <div className="row">
            <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
              <div className="widget about-widget">
                <div className="logo widget-title">
                  <img src={Logo} alt="footer" />
                </div>
                <p>
                  Bustan Al-Sukkar will be offering a harmonious stay experience
                  with nature, reflecting tradition and cultures whilst
                  uplifting our service to the international standard.
                </p>
                <ul>
                  <li>
                    <a
                      onClick={ClickHandler}
                      href="https://www.facebook.com/profile.php?id=100092194246556"
                    >
                      <i className="ti-facebook"></i>
                    </a>
                  </li>

                  <li>
                    <a
                      onClick={ClickHandler}
                      href="https://www.linkedin.com/company/bustan-al-sukkar/"
                    >
                      <i className="ti-linkedin"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={ClickHandler}
                      href="https://instagram.com/bustanalsukkar?igshid=MzRlODBiNWFlZA=="
                    >
                      <i className="ti-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            {
              services && services.length > 0 && (
                   <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
              <div className="widget link-widget s1">
                <div className="widget-title">
                  <h3>What we offer:</h3>
                </div>
                <ul>
                  {services.slice(0,6).map((service, sitem) => (
                    <li key={sitem}>
                      <label>
                        {service.serviceName}
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
              )
            }
         
            <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
              <div className="widget link-widget">
                <div className="widget-title">
                  <h3>Important Link</h3>
                </div>
                <ul>
                  {menu &&
                    menu.map((m) => {
                      return (
                        <li key={m.menuId}>
                          <Link onClick={ClickHandler} to={m.menuURL}>
                            {m.menuName}
                          </Link>
                        </li>
                      );
                    })}
                  <li>
                    <Link onClick={ClickHandler} to={`/rules-&-regulation`}>
                      Rules & Regulation
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
              <div className="widget wpo-service-link-widget">
                <div className="widget-title">
                  <h3>Contact </h3>
                </div>
                <div className="contact-ft">
                  <ul>
                    <li>
                      <i className="fi flaticon-placeholder"></i>New Ainab Village<br/>Aley, Lebanon
                    </li>
                    <li>
                      <i className="fi flaticon-phone-call"></i>
                      <a className="footer-link" href="tel:0096170658888">
                        +961 70 658 888
                      </a>
                    </li>
                    <li>
                      <i className="fi flaticon-send"></i>
                      <a
                        href="mailto:info@bustanalsukkar.com"
                        className="footer-link"
                      >
                        info@bustanalsukkar.com
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="wpo-lower-footer">
        <div className="container">
          <div className="row">
            <div className="col col-xs-12">
              <p className="copyright">
                {" "}
                Copyright &copy; {new Date().getFullYear()} Bustan al Sukkar by{" "}
                <a href="https://vision-more.com/">Vision & more</a>. All Rights
                Reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
