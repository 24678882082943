import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { toast } from "react-toastify";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../images/Bustan-Al-Sukkar--Logo-Final-web-022.png";
import Navbar from "../../components/Navbar";
import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";
import { Global } from "../../Global";

import "./style.scss";
import { Fragment } from "react";
import SocialLoginButton from "../../components/SocialLogin/SocialLoginButton";

const SignUpPage = (props) => {
  const push = useNavigate();

  let strongPasswordRegex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&.?*])(?=.{8,})"
  );

  const [value, setValue] = useState({
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    confirmPassword: "",
  });

  const changeHandler = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
  };

  const submitForm = async (e) => {
    e.preventDefault();
    if (value.email.trim() === "" || value.password.trim() === "") {
      toast.error("Empty field is not allowed!");
    } else if (!value.email.includes(".") && !value.email.includes("@")) {
      toast.error("Valid email is required");
    } else if (value.password !== value.confirmPassword) {
      toast.error("Password doesn't match");
    } else if (!strongPasswordRegex.test(value.password)) {
      toast.error(
        "Password must be minimum 8 characters consisting of numbers, uppercase and lowercase letters and symbols"
      );
    } else {
      const response = await fetch(
        Global.API_URL + "Customer/CustomerRegister",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "bs-api-key": Global.bs_api_key,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": Global.HostAPI,
          },
          body: JSON.stringify({
            firstName: value.firstName,
            lastName: value.lastName,
            email: value.email.trim().toLocaleLowerCase(),
            password: value.password,
            loginTypeId: 1,
            profilePicture: "",
          }),
        }
      );
      const result = await response.json();
      if (response.ok) {
        toast.success(result.message);
        push("/login");
      } else {
        toast.error(result.message);
        setValue({
          email: "",
          firstName: "",
          lastName: "",
          password: "",
          confirmPassword: "",
        });
      }
    }
  };

  return (
    <Fragment>
      <Navbar hclass={"wpo-header-style-3"} Logo={Logo} />
      {/* <PageTitle pageTitle="" pagesub="" /> */}
      <section
        className="error-404-section section-padding-login"
        style={{
          background:
            "url('/images/Login-1.jpg') no-repeat center top/cover",
        }}
      >
        <div className="container">
          <Grid className="loginWrapper">
            <Grid className="loginForm">
              <h2>Signup</h2>
              <p>Create an account</p>
              <form onSubmit={submitForm}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      className="inputOutline"
                      fullWidth
                      placeholder="First Name"
                      value={value.firstName}
                      variant="outlined"
                      name="firstName"
                      label="First Name *"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onBlur={(e) => changeHandler(e)}
                      onChange={(e) => changeHandler(e)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      className="inputOutline"
                      fullWidth
                      placeholder="Last Name"
                      value={value.lastName}
                      variant="outlined"
                      name="lastName"
                      label="Last Name *"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onBlur={(e) => changeHandler(e)}
                      onChange={(e) => changeHandler(e)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      className="inputOutline"
                      fullWidth
                      placeholder="E-mail"
                      value={value.email}
                      variant="outlined"
                      name="email"
                      label="E-mail *"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onBlur={(e) => changeHandler(e)}
                      onChange={(e) => changeHandler(e)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      className="inputOutline"
                      fullWidth
                      placeholder="Password"
                      type="password"
                      value={value.password}
                      variant="outlined"
                      name="password"
                      label="Password *"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onBlur={(e) => changeHandler(e)}
                      onChange={(e) => changeHandler(e)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      className="inputOutline"
                      fullWidth
                      placeholder="Confirm Password"
                      type="password"
                      value={value.confirmPassword}
                      variant="outlined"
                      name="confirmPassword"
                      label="Confirm Password *"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onBlur={(e) => changeHandler(e)}
                      onChange={(e) => changeHandler(e)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid className="formFooter">
                      <Button
                        fullWidth
                        className="cBtn cBtnLarge cBtnTheme"
                        type="submit"
                      >
                        Sign Up
                      </Button>
                    </Grid>
                    <Grid className="loginWithSocial">
                      <SocialLoginButton />
                    </Grid>
                    <p className="noteHelp">
                      Already have an account?{" "}
                      <Link to="/login">Return to Sign In</Link>
                    </p>
                  </Grid>
                </Grid>
              </form>
              <div className="shape-img">
                <i className="fi flaticon-honeycomb"></i>
              </div>
            </Grid>
          </Grid>
        </div>
      </section>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default SignUpPage;
