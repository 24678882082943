import React , {useRef , useEffect , useState} from "react";
import { Navigation, Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { Global } from "../../Global";
import { useSelector } from "react-redux";

const Hero = (props) => {
  const slides = useSelector((state) => state.widget.slides);
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    <section className="wpo-hero-slider">
      <div className="swiper-container">
        <div className="swiper-wrapper">
          <Swiper
            // install Swiper modules
            modules={[Navigation, Pagination, A11y]}
            spaceBetween={0}
            slidesPerView={1}
            pagination={{ clickable: true }}
            loop={true}
            speed={1800}
            parallax={true}
            navigation
          >
            { slides && slides.length > 0 &&
              slides.map((slide, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div
                      className="swiper-slide"
                      style={{
                        background: `url(${
                          windowSize[0] > 1000 ? Global.PHOTO_URL + slide.slideImage : Global.PHOTO_URL + slide.slideMobileImage
                        })`,
                      }}
                    >
                      <div className="slide-inner slide-bg-image">
                        <div className="container-fluid">
                          <div className="slide-content">
                            <div
                              data-swiper-parallax="300"
                              className="slide-title"
                            >
                              <h2>{slide.slideTitle}</h2>
                            </div>
                            <div className="clearfix"></div>
                            <div
                              data-swiper-parallax="500"
                              className="slide-btns"
                            >
                              {/* <a href={slide.slideURL} className="theme-btn">
                                {slide.buttonTitle}
                              </a> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            ...
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default Hero;
