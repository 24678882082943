import React, { useEffect, useState, useRef } from "react";
import { Global } from "../../Global";

const Destination2 = (props) => {
  const [services, setServices] = useState([]);
  const [isFetching, setIsFetching] = useState(1);
  const [count, setCount] = useState(0);
  const SevicePageRef = useRef();
  const [isLoading, setIsLoading] = useState(true);

  const GetServices = async (Skip, Array) => {
    setIsFetching(1);
    let newArray = [];
    try {
      const response = await fetch(
        Global.API_URL +
          "RoomService/GetRoomServiceList?Skip=" +
          Skip +
          "&Take=6",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "bs-api-key": Global.bs_api_key,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Something went wrong!");
      }
      if (response.ok) {
        const result = await response.json();

        if (result.message.length !== 0) {
          setIsFetching(0);
        }

        if (Skip === 0) {
          newArray = result.message.filter((r) => r.isPublished);
        } else {
          newArray = Array.concat(result.message.filter((r) => r.isPublished));
        }
        setServices(newArray);
        setCount(count + 6);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    setIsLoading(true);
    GetServices(0, []);
  }, []);

  window.onscroll = function () {
    if (SevicePageRef.current && isFetching === 0) {
      GetServices(count, services);
    }
  };

  return (
    <div className="wpo-destination-area pt-120" ref={SevicePageRef}>
      <div className="container">
        <div className="destination-wrap">
          <div className="row">
            {isLoading
              ? [1, 2, 3].map((item) => (
                  <div className="col-lg-4 col-md-6 col-12 margintop-50" key={item}>
                    <div className="skeleton-destination-item">
                      <div className="destination-img"></div>
                      <div className="destination-content">
                        <div className="skeleton-label"></div>
                      </div>
                    </div>
                  </div>
                ))
              : services.map((service, ditem) => (
                  <div className="col-lg-4 col-md-6 col-12" key={ditem}>
                    <div className="destination-item">
                      <div className="destination-img">
                        <img
                          src={Global.PHOTO_URL + service.roomServiceImage}
                          alt=""
                        />
                      </div>
                      <div className="destination-content">
                        <h2>
                          <label>{service.roomServiceName}</label>
                        </h2>
                      </div>
                    </div>
                  </div>
                ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Destination2;
