import React, { Fragment, useState, useCallback, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Collapse from "@material-ui/core/Collapse";
import FontAwesome from "../../components/UiStyle/FontAwesome";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import { useNavigate } from "react-router-dom";
import { Global } from "../../Global";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
// images
import visa from "../../images/icon/visa.png";
import mastercard from "../../images/icon/mastercard.png";
import skrill from "../../images/icon/skrill.png";
import paypal from "../../images/icon/paypal.png";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import "./style.scss";

const cardType = [
  {
    title: "visa",
    img: visa,
  },
  {
    title: "mastercard",
    img: mastercard,
  },
  {
    title: "skrill",
    img: skrill,
  },
  {
    title: "paypal",
    img: paypal,
  },
];

const CheckoutSection = ({ cartList }) => {
  const { customerToken, token, customerId, guestId } = useSelector(
    (state) => state.credential
  );
  const {
    roomName,
    roomId,
    isBreakfastIncluded,
    breakfastPrice,
    checkinDate,
    checkoutDate,
    pricePerNight,
    nbofNights,
  } = useSelector((state) => state.cart.details);
  const navigate = useNavigate();
  // states
  const [tabs, setExpanded] = useState({
    billing_adress: true,
    payment: true,
  });

  const [paymentMethod, setPaymentMethod] = useState("cash");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [cityId, setCityId] = useState(0);

  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);

  const [isTravelingForWork, setIsTravelingForWork] = useState(false);
  const [isWithBreakfast, setIsWithBreakfast] = useState(false);

  // tabs handler
  function faqHandler(name) {
    setExpanded((...prevState) => ({
      ...prevState,
      payment: true,
      [name]: !tabs[name],
    }));
  }

  const GetCitiesByCountryCode = useCallback(async (code, city) => {
    if (code !== "") {
      try {
        const response = await fetch(
          Global.API_URL + "City/GetCitiesForCountry?countrycode=" + code,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "bs-api-key": Global.bs_api_key,
            },
          }
        );
        const result = await response.json();
        if (result.code === 0) {
          setCountryCode(code);
          setCityId(city);
          setCities(result.message);
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setCountryCode("");
      setCityId(0);
      setCities([]);
    }
  }, []);

  const AddBooking = async () => {
    if (
      firstName.trim() === "" ||
      lastName.trim() === "" ||
      email.trim() === "" ||
      phone.trim() === "" ||
      countryCode === "" ||
      cityId === 0
    ) {
      toast.error("All fields are required");
    } else {
      try {
        let response;
        if (customerId === 0) {
          response = await fetch(Global.API_URL + "Booking/AddBooking", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "bs-api-key": Global.bs_api_key,
              AuthorizationGuest: token,
            },
            body: JSON.stringify({
              roomId: roomId,
              bookingAddress: {
                firstName: firstName,
                lastName: lastName,
                email: email,
                phone: phone,
                countryCode: countryCode,
                cityId: cityId,
                customerId: customerId,
                guestId: guestId,
              },
              paymentMethodId: paymentMethod === "card" ? 1 : 2,
              checkinDate: checkinDate,
              checkoutDate: checkoutDate,
              isIncluded: isWithBreakfast,
              isTravelingForWork: isTravelingForWork,
            }),
          });
        } else {
          response = await fetch(Global.API_URL + "Booking/AddBooking", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "bs-api-key": Global.bs_api_key,
              AuthorizationCustomer: customerToken,
            },
            body: JSON.stringify({
              roomId: roomId,
              bookingAddress: {
                firstName: firstName,
                lastName: lastName,
                email: email,
                phone: phone,
                countryCode: countryCode,
                cityId: cityId,
                customerId: customerId,
                guestId: 0,
              },
              paymentMethodId: paymentMethod === "card" ? 1 : 2,
              checkinDate: checkinDate,
              checkoutDate: checkoutDate,
              isIncluded: isWithBreakfast,
              isTravelingForWork: isTravelingForWork,
            }),
          });
        }

        const result = await response.json();
        if (result.code === 0) {
          navigate("/order_received");
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    const GetCountries = async () => {
      try {
        const response = await fetch(
          Global.API_URL + "Country/GetListCountries",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "bs-api-key": Global.bs_api_key,
            },
          }
        );
        const result = await response.json();
        if (result.code === 0) {
          setCountries(result.message);
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        console.log(error);
      }
    };

    GetCountries();
  }, [customerToken]);

  return (
    <Fragment>
      <Grid className="checkoutWrapper section-padding">
        <Grid className="container" container spacing={3}>
          <Grid item md={6} xs={12}>
            <div className="check-form-area">
              <Grid className="cuponWrap checkoutCard">
                <Button
                  className="collapseBtn"
                  fullWidth
                  onClick={() => faqHandler("billing_adress")}
                >
                  Billing Address
                  <FontAwesome name={tabs.billing_adress ? "minus" : "plus"} />
                </Button>
                <Collapse in={tabs.billing_adress} timeout="auto" unmountOnExit>
                  <Grid className="chCardBody">
                    <form className="cuponForm">
                      <Grid container spacing={3}>
                        <Grid item sm={6} xs={12}>
                          <TextField
                            fullWidth
                            label="First Name"
                            name="firstName"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            type="text"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            className="formInput radiusNone"
                          />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <TextField
                            fullWidth
                            label="Last Name"
                            name="lastName"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            type="text"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            className="formInput radiusNone"
                          />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <TextField
                            fullWidth
                            label="Email Address"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            type="email"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            className="formInput radiusNone"
                          />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <TextField
                            fullWidth
                            label="Phone No"
                            name="phone"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            type="text"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            className="formInput radiusNone"
                          />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <InputLabel id="demo-simple-select-filled-label">
                            Country
                          </InputLabel>
                          <FormControl
                            className="formSelect"
                            fullWidth
                            variant="filled"
                          >
                            <Select
                              labelId="demo-simple-select-filled-label"
                              id="demo-simple-select-filled"
                              value={countryCode}
                              name="countryCode"
                              onChange={(e) =>
                                GetCitiesByCountryCode(e.target.value, 0)
                              }
                            >
                              <MenuItem value="">
                                <em>Select</em>
                              </MenuItem>
                              {countries.map((country, index) => (
                                <MenuItem
                                  key={index}
                                  value={country.countryCode}
                                >
                                  {country.countryName}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <InputLabel id="demo-simple-select-filled-label">
                            City
                          </InputLabel>
                          <FormControl
                            className="formSelect"
                            fullWidth
                            variant="filled"
                          >
                            <Select
                              labelId="demo-simple-select-filled-label"
                              id="demo-simple-select-filled"
                              value={cityId}
                              name="cityId"
                              onChange={(e) => setCityId(e.target.value)}
                            >
                              <MenuItem value={0}>
                                <em>Select</em>
                              </MenuItem>
                              {cities.map((city, index) => (
                                <MenuItem key={index} value={city.cityId}>
                                  {city.cityName}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        {!isBreakfastIncluded && (
                          <Grid item xs={6}>
                            <FormControlLabel
                              className="checkBox"
                              control={
                                <Checkbox
                                  checked={isWithBreakfast}
                                  onChange={() => {
                                    setIsWithBreakfast(!isWithBreakfast);
                                  }}
                                  value={isWithBreakfast}
                                  color="primary"
                                />
                              }
                              label="Add breakfast"
                            />
                          </Grid>
                        )}
                        <Grid item xs={6}>
                          <FormControlLabel
                            className="checkBox"
                            control={
                              <Checkbox
                                checked={isTravelingForWork}
                                onChange={() =>
                                  setIsTravelingForWork(!isTravelingForWork)
                                }
                                value={isTravelingForWork}
                                color="primary"
                              />
                            }
                            label="Traveling for work"
                          />
                        </Grid>
                      </Grid>
                    </form>
                  </Grid>
                </Collapse>
              </Grid>
              <Grid className="cuponWrap checkoutCard">
                <Button
                  className="collapseBtn"
                  fullWidth
                  onClick={() => faqHandler("payment")}
                >
                  Payment Method
                  <FontAwesome name={tabs.payment ? "minus" : "plus"} />
                </Button>
                <Grid className="chCardBody">
                  <Collapse in={tabs.payment} timeout="auto">
                    <RadioGroup
                      className="paymentMethod"
                      aria-label="Payment Method"
                      name="payment_method"
                      value={paymentMethod}
                      onChange={(e) => setPaymentMethod(e.target.value)}
                    >
                      {/* <FormControlLabel
                        value="card"
                        control={<Radio color="primary" />}
                        label="Payment By Card "
                      /> */}
                      <FormControlLabel
                        value="cash"
                        control={<Radio color="primary" />}
                        label="50% OMT 50% on arrival"
                      />
                      <OverlayTrigger
                        key="right"
                        placement="right"
                        overlay={
                          <Tooltip>
                            Reservation fees are to be paid using OMT sent to
                            “Karim Salah Sukkar” while sending a copy of the
                            receipt to “+961 70 658 888”
                          </Tooltip>
                        }
                      >
                        <button
                          className="btn btn-sm float-right"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i
                            className="fa fa-question-circle"
                            aria-hidden="true"
                          ></i>
                        </button>
                      </OverlayTrigger>
                    </RadioGroup>
                    {/* <Collapse in={paymentMethod === "card"} timeout="auto">
                      <Grid className="cardType">
                        {cardType.map((item, i) => (
                          <Grid key={i} className="cardItem">
                            <img src={item.img} alt={item.title} />
                          </Grid>
                        ))}
                      </Grid>
                      <Grid className="cardType">
                        <button
                          className="cBtn cBtnLarge cBtnTheme mt-20 ml-15"
                          type="submit"
                          onClick={AddBooking}
                        >
                          Proceed to Checkout
                        </button>
                      </Grid>
                    </Collapse> */}
                    <Collapse in={paymentMethod === "cash"} timeout="auto">
                      <Grid className="cardType">
                        <button
                          className="cBtn cBtnLarge cBtnTheme mt-20 ml-15"
                          type="submit"
                          onClick={AddBooking}
                        >
                          Proceed to Checkout
                        </button>
                      </Grid>
                    </Collapse>
                  </Collapse>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item md={6} xs={12}>
            <Grid className="cartStatus">
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Grid className="cartTotals">
                    <h4>Cart</h4>
                    <Table>
                      <TableBody>
                        <TableRow className="totalProduct">
                          <TableCell>Room Name</TableCell>
                          <TableCell align="right">{roomName}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Sub Price</TableCell>
                          <TableCell align="right">
                            ${Math.round(pricePerNight)} x{" "}
                            {nbofNights + " nights"}
                          </TableCell>
                        </TableRow>
                        {!isBreakfastIncluded && isWithBreakfast ? (
                          <Fragment>
                            <TableRow>
                              <TableCell>Breakfast Price</TableCell>
                              <TableCell align="right">
                                ${breakfastPrice}{" "}
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell>Total Price</TableCell>
                              <TableCell align="right">
                                $
                                {Math.round(pricePerNight) * nbofNights +
                                  breakfastPrice}{" "}
                              </TableCell>
                            </TableRow>
                          </Fragment>
                        ) : (
                          <TableRow>
                            <TableCell>Total Price</TableCell>
                            <TableCell align="right">
                              ${Math.round(pricePerNight) * nbofNights}{" "}
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default CheckoutSection;
