import React, { Fragment } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Homepage from "../HomePage";
import AboutPage from "../AboutPage/AboutPage";
import CartPage from "../CartPage";
import CheckoutPage from "../CheckoutPage";
import OrderRecived from "../OrderRecived";
import RoomPage from "../RoomPage";
import RoomSinglePage from "../RoomSinglePage";
import SearchResults from "../SearchResults/SearchResults";
import ErrorPage from "../ErrorPage/ErrorPage";
import ContactPage from "../ContactPage/ContactPage";
import ConfirmEmail from "../ConfirmEmail";
import ProfilePage from "../ProfilePage/ProfilePage";
import BookingDetailPage from "../BookingDetailPage/BookingDetailPage";
import TermsAndConditions from "../Terms&conditions";
import LoginPage from "../LoginPage";
import SignUpPage from "../SignUpPage";
import ForgotPassword from "../ForgotPassword";
import ChangePassword from "../ChangePassword";
import BlogDetails from "../BlogDetails";
import { useSelector } from "react-redux";
import { Suspense } from "react";
import DestinationPage from "../DestinationPage/DestinationPage";

const AllRoute = () => {
  const { isLogged } = useSelector((state) => state.credential);

  return (
    <div className="App">
      <BrowserRouter>
        <Suspense
          fallback={
            <div className="egns-preloader">
              <div className="container">
                <div className="row d-flex justify-content-center">
                  <div className="col-6">
                    <div className="circle-border">
                      <div className="moving-circle"></div>
                      <div className="moving-circle"></div>
                      <div className="moving-circle"></div>
                      <svg
                        width="180px"
                        height="150px"
                        viewBox="0 0 187.3 93.7"
                        preserveAspectRatio="xMidYMid meet"
                      >
                        <path
                          stroke="#df865d"
                          id="outline"
                          fill="none"
                          strokeWidth="4"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeMiterlimit="10"
                          d="M93.9,46.4c9.3,9.5,13.8,17.9,23.5,17.9s17.5-7.8,17.5-17.5s-7.8-17.6-17.5-17.5c-9.7,0.1-13.3,7.2-22.1,17.1 c-8.9,8.8-15.7,17.9-25.4,17.9s-17.5-7.8-17.5-17.5s7.8-17.5,17.5-17.5S86.2,38.6,93.9,46.4z"
                        ></path>
                        <path
                          id="outline-bg"
                          opacity="0.05"
                          fill="none"
                          stroke="#df865d"
                          strokeWidth="4"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeMiterlimit="10"
                          d="M93.9,46.4c9.3,9.5,13.8,17.9,23.5,17.9s17.5-7.8,17.5-17.5s-7.8-17.6-17.5-17.5c-9.7,0.1-13.3,7.2-22.1,17.1 c-8.9,8.8-15.7,17.9-25.4,17.9s-17.5-7.8-17.5-17.5s7.8-17.5,17.5-17.5S86.2,38.6,93.9,46.4z"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        >
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="home" element={<Homepage />} />
            <Route path="about" element={<AboutPage />} />
            <Route path="search-result" element={<SearchResults />} />
            <Route path="room-single/:id" element={<RoomSinglePage />} />
            <Route path="cart" element={<CartPage />} />
            <Route path="checkout" element={<CheckoutPage cartList={[]} />} />
            <Route path="order_received" element={<OrderRecived />} />
            <Route path="villas" element={<RoomPage />} />
            <Route path="booking-detail/:booking_id" element={<BookingDetailPage />} />

            <Route path="/*" element={<ErrorPage />} />
            <Route path="404" element={<ErrorPage />} />
            <Route path="contact" element={<ContactPage />} />
            <Route path="rules-&-regulation" element={<TermsAndConditions />} />

            <Route path="services" element={<DestinationPage />} />
            <Route path="news/:id" element={<BlogDetails />} />


            {!isLogged ? (
              <Fragment>
                <Route path="login" element={<LoginPage />} />
                <Route path="register" element={<SignUpPage />} />
                <Route path="forgot-password" element={<ForgotPassword />} />
                <Route path="confirm-email" element={<ConfirmEmail />} />
                <Route path="reset-password" element={<ChangePassword />} />
              </Fragment>
            ) : (
              <Route path="my-account" element={<ProfilePage />} />
            )}
          </Routes>
        </Suspense>
      </BrowserRouter>
    </div>
  );
};

export default AllRoute;
