import React, { useState, useEffect, Fragment } from "react";
import { Global } from "../../Global";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ServiceSection from "../../components/ServiceSection";

const BookingDetail = (props) => {
  const { booking_id } = useParams();

  const [booking, setBooking] = useState({
    bookingAddressCity: "",
    bookingAddressCountry: "",
    bookingAddressEmail: "",
    bookingAddressFirstName: "",
    bookingAddressId: 0,
    bookingAddressLastName: "",
    bookingAddressPhone: "",
    bookingId: 0,
    bookingStatusId: 0,
    bookingStatusName: "",
    breakfastFee: 0,
    roomBreakfastPrice: 0,
    checkinDate: "2023-05-08T00:00:00",
    checkoutDate: "2023-05-08T00:00:00",
    city: 0,
    country: "",
    floor: 0,
    hotelId: 0,
    hotelImage: "",
    hotelName: "",
    isCanceled: false,
    isIncludedBreakfast: false,
    isTravelingForWork: false,
    maxAdults: 0,
    maxChildren: 0,
    maxInfants: 0,
    nbOfBaths: 0,
    nbOfGuests: 0,
    paymentMethodId: 0,
    paymentMethodName: "",
    reservationDate: "2023-05-08T06:48:34.607",
    roomAllowedItems: [],
    roomDescription: "",
    roomId: 0,
    roomImages: {
      roomImageId: 0,
      roomImageURL: "",
    },
    roomName: "",
    roomServices: [],
    roomShortDescription: "",
    subtotalPrice: 0,
    totalNbOfBeds: 0,
    totalNbofBedRooms: 0,
    totalPrice: 0,
  });

  useEffect(() => {
    const GetBooking = async () => {
      if (parseInt(booking_id) !== 0) {
        try {
          const response = await fetch(
            Global.API_URL + "Booking/GetBookingByEncryptedID?bookingid=" + booking_id,
            {
              method: "GET",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "bs-api-key": Global.bs_api_key,
              },
            }
          );
          const result = await response.json();
          if (result.code === 0) {
            let offset, date, newDate;

            offset = new Date(
              result.message.reservationDate
            ).getTimezoneOffset();
            date = new Date(result.message.reservationDate);
            newDate = new Date(date.getTime() - offset * 60 * 1000);
            result.message.reservationDate = newDate;

            setBooking(result.message);
          } else {
            toast.error(result.message);
          }
        } catch (error) {
          console.log(error);
        }
      }
    };

    GetBooking();
  }, [booking_id]);

  return (
    <Fragment>
      <section className="wpo-about-section section-padding">
        <div className="container">
          <div className="wpo-about-section-wrapper">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-img">
                  <img
                    src={Global.PHOTO_URL + booking.roomImages.roomImageURL}
                    alt=""
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-content">
                  <div className="about-title">
                    <span>{booking.bookingStatusName}</span>
                    <h2>{booking.roomName}</h2>
                  </div>
                  <div className="wpo-about-content-inner">
                    <p>
                      {" "}
                      {booking.bookingAddressFirstName}{" "}
                      {booking.bookingAddressLastName}
                    </p>
                    <p>{booking.bookingAddressEmail}</p>
                    <p> {booking.bookingAddressPhone}</p>
                    <p>
                      {booking.bookingAddressCountry}, {booking.bookingAddressCity}
                    </p>
                    <p>
                      From {new Date(booking.checkinDate).toLocaleString()} to{" "}
                      {new Date(booking.checkoutDate).toLocaleString()}
                    </p>
                    <p>{booking.totalPrice} USD</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ServiceSection services={booking.roomServices} />
    </Fragment>
  );
};

export default BookingDetail;
