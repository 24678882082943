import React from "react";
import { Global } from "../../Global";

const AllowedItemSection2 = ({
  allowedItems,
  widgetTitle,
  widgetDescription,
  widgetUrl,
  page,
}) => {

  return (
    <div className="wpo-service-area section-padding space-section">
      <div className="wpo-service-wrap">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-4 col-lg-8 col-12">
              <div className="wpo-service-content">
                <h2>{widgetTitle}</h2>
                <p>{widgetDescription} </p>
                {/* <Link className="theme-btn" to={widgetUrl}>
                  Discover More
                </Link> */}
              </div>
            </div>
            <div className="col-xl-8 col-12">
              <div className="wpo-service-items">
                {allowedItems.map((item, index) => (
                  <div className="wpo-service-item" key={index}>
                    <img src={Global.PHOTO_URL + item.itemIcon} alt=""></img>
                    <label>
                      {item.itemName}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllowedItemSection2;
