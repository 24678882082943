import React, { useState, useCallback } from "react";
import Slider from "react-slick";
import { Global } from "../../Global";
import { useEffect } from "react";
// import FsLightbox from "fslightbox-react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { ModalContent } from "./Modal";

const Rooms = ({ images }) => {
  const [toggler, setToggler] = useState(false);
  const [roomImages, setRoomImage] = useState([]);
  var settings = {
    dots: false,
    arrows: true,
    speed: 500,
    slidesToShow: 4,
    infinite: images && images.length > 4 ? true : false,
    slidesToScroll: 1,
    autoplay: true,
    centerMode: false,
    autoplaySpeed: 3500,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const openImageViewer = useCallback((index) => {
    setToggler(true);
  }, []);

  useEffect(() => {
    window.scrollTo(10, 0);
    var image = [];
    images.forEach((element) => {
      image.push(Global.PHOTO_URL + element);
    });
    setRoomImage(image);
  }, [images]);

  return (
    <div className="wpo-room-area-s3">
      <div className="container-fluid">
        <div className="room-wrap room-active">
          <Slider {...settings}>
            {images.length > 0 &&
              images.map((image, pitem) => (
                <div className="room-item" key={pitem}>
                  <div className="room-img">
                    {/* <Zoom> */}
                    <img
                      src={Global.PHOTO_URL + image}
                      onClick={() => openImageViewer(pitem)}
                      alt="images"
                    />
                    {/* </Zoom> */}
                  </div>
                </div>
              ))}
          </Slider>
        </div>
      </div>
      {toggler && (
        <ModalContent onClose={() => setToggler(false)}>
          <Swiper className="mySwiper" loop lazy>
            {roomImages.map((image) => {
              return (
                <SwiperSlide>
                  <img src={image} alt="" />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </ModalContent>
      )}
      {/* <FsLightbox
        toggler={toggler}
        sources={roomImages}
        sourceIndex={currentImage}
        types={[...new Array(roomImages.length).fill("image")]}
        disableBackgroundClose={false}
        onClose={()=>closeImageViewer()}
      /> */}

      {/* {isViewerOpen && (
        <ImageViewer
          src={roomImages}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
            zIndex:999999
          }}
          closeOnClickOutside={true}
        />
      )} */}
    </div>
  );
};

export default Rooms;
