import React from "react";
import { Link } from "react-router-dom";
import Services from "../../api/service";

const BlogSidebar = (props) => {

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <div className={`col col-lg-4 col-12 ${props.blLeft}`}>
      <div className="blog-sidebar">
        <div className="widget wpo-instagram-widget">
          <div className="widget-title">
            <h3>Related Posts</h3>
          </div>
          <ul className="d-flex">
            {Services.slice(0, 6).map((service, sitem) => (
              <li key={sitem}>
                <Link onClick={ClickHandler} to={`/`}>
                  <img src={service.simg1} alt="" />
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="wpo-contact-widget widget">
          <h2>
            How We Can <br /> Help You!
          </h2>
          <p>
            labore et dolore magna aliqua. Quis ipsum suspendisse ultrices
            gravida. Risus commodo viverra maecenas accumsan lacus vel
            facilisis.{" "}
          </p>
          <Link onClick={ClickHandler} to="/contact">
            Contact Us
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BlogSidebar;
